import vsf, { definePage } from '@vs/vsf-boot';
import {
  NavBar,
  Box,
  FormItem,
  Form,
  Input,
  Button,
  Switch,
} from '@vs/vsf-mobile';
import React, { useState, useEffect } from 'react';
import FixedBtn from '@/modal/fixedBtn';
import LicenseKeyboard from 'vehicle-plate-keyboard';
import 'vehicle-plate-keyboard/dist/main.css';
import useMonitorSoftKeyboard from './useMonitorSoftKeyboard';
import './index.less';
const initialCount = 60;

const CarInput = ({
  value,
  onChange,
  placeholder,
  showKeyboard,
  setShowKeyboard,
  disabled,
}) => {
  return (
    <div className="car_input">
      <div
        className={value ? 'car_input_text' : 'placeholder'}
        onClick={() => !disabled && setShowKeyboard(true)}
      >
        {value || placeholder}
      </div>
      <LicenseKeyboard
        visible={disabled ? false : showKeyboard}
        onChange={onChange}
        value={value || ''}
        done={() => setShowKeyboard(false)}
      />
    </div>
  );
};
const VistorCreate = (props) => {
  const { global } = props?.stores || {};
  const { userInfo, currentCampus } = global || {};
  console.log(props.routes.query.id);
  const [disabled, setDisabled] = useState(true);
  const [readOnly, setReadOnly] = useState(false);
  const [count, setCount] = useState(initialCount);
  const [isCounting, setIsCounting] = useState(false);
  const [showKeyboard, setShowKeyboard] = useState(false);
  const [isDrive, setIsDrive] = useState(false);
  const monitorSoftKeyboard = useMonitorSoftKeyboard();
  const validatePhoneNumber = (_, value) => {
    const phoneRegex = /^1[3-9]\d{9}$/; // 适用于中国大陆的手机号格式
    if (!value) {
      setDisabled(true);
      return Promise.reject(new Error('请输入手机号码'));
    } else if (!phoneRegex.test(value)) {
      setDisabled(true);
      return Promise.reject(new Error('请输入有效的手机号码'));
    }
    setDisabled(false);
    return Promise.resolve();
  };
  const isVehicleNumber = (_, value) => {
    const phoneRegex =
      /((^(京|津|沪|渝|冀|豫|云|辽|黑|湘|皖|鲁|新|苏|浙|赣|鄂|桂|甘|晋|蒙|陕|吉|闽|贵|粤|青|藏|川|宁|琼)[A-Z]{1}[A-Z0-9]{5}[A-Z0-9挂学警港澳试超使领北南军海空外]{1}$)|(^(京|津|沪|渝|冀|豫|云|辽|黑|湘|皖|鲁|新|苏|浙|赣|鄂|桂|甘|晋|蒙|陕|吉|闽|贵|粤|青|藏|川|宁|琼)[A-Z]{1}[A-Z0-9]{4}[A-Z0-9挂学警港澳试超使领北南军海空外]{1}$))/;
    if (!value) {
      return Promise.reject(new Error('请输入车牌号码'));
    } else if (!phoneRegex.test(value)) {
      return Promise.reject(new Error('请输入有效的车牌号码'));
    }
    return Promise.resolve();
  };

  const onSend = async () => {
    //
    // Tips: 以下是你选择的请求函数
    const res = await vsf.services?.VisitorController_getCaptcha_c9b8c5?.({
      // TODO: 请检查以下这部分传参是否符合需求
      cellPhone: vsf.refs.form3769.getFieldValue('phone'),
    }); // TODO: 你可能需要整理返回数据格式
    return res?.data;
  };

  useEffect(() => {
    let timer = null;
    if (isCounting && count > 0) {
      timer = setTimeout(() => setCount(count - 1), 1000);
    } else if (count === 0) {
      setIsCounting(false);
      setCount(initialCount);
    }
    return () => clearTimeout(timer);
  }, [count, isCounting, initialCount]);

  useEffect(() => {
    if (!monitorSoftKeyboard.isDownSoftKeyboard) {
      setShowKeyboard(false);
    }
  }, [monitorSoftKeyboard]);

  const handleClick = () => {
    if (!isCounting) {
      setIsCounting(true);
      setCount(initialCount);
      onSend && onSend();
    }
  };

  const reqVistorDetail = async (params) => {
    if (!props.routes.query.id) return;
    //
    // Tips: 以下是你选择的请求函数
    const res =
      await vsf.services?.VisitorController_getByIdAndCampusId_6b9aad?.({
        // TODO: 请检查以下这部分传参是否符合需求
        var: {
          id: props.routes.query.id,
          campusId: currentCampus.campusId,
        },
      }); // TODO: 你可能需要整理返回数据格式
    if (res.code === 200) {
      vsf.refs.form3769.setFieldsValue(res.data);
    }
    return res?.data;
  };
  const scrollToBottom = () => {
    const div = document.getElementById('up_dom');
    if (div) {
      const scrollHeight = div.scrollHeight; //里面div的实际高度  2000px
      const height = div.clientHeight; //网页可见高度  200px
      const maxScrollTop = scrollHeight - height;
      div.scrollTop = maxScrollTop > 0 ? maxScrollTop : 0;
      //如果实际高度大于可见高度，说明是有滚动条的，则直接把网页被卷去的高度设置为两个div的高度差，实际效果就是滚动到底部了。
    }
  };
  useEffect(() => {
    reqVistorDetail();
  }, []);

  useEffect(() => {
    showKeyboard && scrollToBottom();
  }, [showKeyboard]);

  return (
    <>
      <NavBar
        children={props.routes.query.id ? '到访信息详情' : '到访登记'}
        backArrow={true}
        onBack={() => vsf.goBack()}
      />
      <Box
        id="up_dom"
        className={
          showKeyboard ? 'page-vistor-create up_page' : 'page-vistor-create'
        }
      >
        <Form
          vsid="37695"
          id="form3769"
          layout="horizontal"
          requiredMarkStyle="none"
          hasFeedback={true}
          readOnly={readOnly || props.routes.query.id}
          style={{
            '--border-bottom': '1px solid #EBEFF4',
            '--prefix-width': '80px',
            '--text-align': 'right',
          }}
          initialValues={{ isDrive: false }}
          onChange={(v) => {
            setIsDrive(v.isDrive);
            if (!v.isDrive) {
              setShowKeyboard(false);
            }
          }}
          footer={false}
          onFinish={async (params) => {
            const res = await vsf.services?.VisitorController_create_ebe62f?.({
              // TODO: 请检查以下这部分传参是否符合需求
              btoParam: { ...params, campusId: props.routes.query.campusId },
            }); // TODO: 你可能需要整理返回数据格式
            if (res.code === 200) {
              setReadOnly(true);
              setIsCounting(false);
              setCount(initialCount);
              setDisabled(true);
              vsf.showToast('提交成功');
            }
            return res?.data;
          }}
        >
          <div className="form-box" onClick={() => setShowKeyboard(false)}>
            <FormItem
              name="visitor"
              label="到访人"
              rules={[{ required: true, message: '请输入姓名' }]}
            >
              <Input placeholder="请输入姓名" />
            </FormItem>
            <FormItem
              name="phone"
              label="手机号码"
              validateTrigger="onBlur"
              rules={[
                {
                  required: true,
                  validator: validatePhoneNumber,
                },
              ]}
            >
              <Input placeholder="请输入手机号码" />
            </FormItem>
            {!props.routes.query.id && (
              <div className="code">
                <FormItem
                  rules={[{ required: true, message: '请输入验证码' }]}
                  name="code"
                  extra={
                    <Button
                      className="code-btn"
                      children={isCounting ? `${count}秒后重发` : '获取验证码'}
                      disabled={disabled || isCounting}
                      onClick={handleClick}
                    />
                  }
                >
                  <Input placeholder="请输入验证码" />
                </FormItem>
              </div>
            )}
            <FormItem
              name="idNumber"
              label="证件号码"
              required={true}
              className="last-form-item"
              rules={[{ required: true, message: '请输入证件号码' }]}
            >
              <Input placeholder="请输入证件号码" />
            </FormItem>
          </div>
          <div
            className="form-box form-box1"
            onClick={() => setShowKeyboard(false)}
          >
            <FormItem
              name="interviewee"
              label="受访人"
              required={true}
              rules={[{ required: true, message: '请输入受访人姓名' }]}
            >
              <Input placeholder="请输入受访人姓名" />
            </FormItem>
            <FormItem
              name="reason"
              required={true}
              label="到访事由"
              className="last-form-item"
              rules={[{ required: true }]}
            >
              <Input placeholder="请输入到访事由" />
            </FormItem>
          </div>
          <div className="form-box form-box2">
            <FormItem
              name="isDrive"
              label="是否开车"
              childElementPosition="right"
              className={
                isDrive ? 'required-form' : 'last-form-item required-form'
              }
              valuePropName="checked"
            >
              <Switch uncheckedText="否" checkedText="是" />
            </FormItem>
            {isDrive || props.routes.query.id ? (
              <FormItem
                name="driveNumber"
                label="车牌号码"
                required={true}
                className="last-form-item"
                rules={[
                  {
                    required: true,
                    validator: isVehicleNumber,
                  },
                ]}
              >
                <CarInput
                  showKeyboard={showKeyboard}
                  setShowKeyboard={setShowKeyboard}
                  disabled={readOnly || !!props.routes.query.id}
                  placeholder="请输入车牌号码"
                />
              </FormItem>
            ) : null}
          </div>
        </Form>
        {!readOnly && !props.routes.query.id && (
          <FixedBtn
            onClick={() => vsf.refs.form3769.submit()}
            text="确定提交"
          />
        )}
      </Box>
    </>
  );
};
export default definePage(VistorCreate);

import './index.less';

import vsf, { definePage } from '@vs/vsf-boot';
import { Box, InfiniteScroll, NavBar } from '@vs/vsf-mobile';
import React, { useEffect, useState } from 'react';

import addSvg from '@/assets/icons/add.svg';
import FixedBtn from '@/modal/fixedBtn';
import MyEmpty from '@/modal/myEmpty';
interface GroupItem {
  id: number;
  createdAt: string;
  regionalName: string;
  teamName: string;
}
const Grouping: React.FC = (props: any) => {
  const { global } = props?.stores || {};
  const { userInfo, currentCampus } = global || {};
  const [groupList, setGroupList] = useState<GroupItem[]>([]);
  const [hasMore, setHasMore] = useState(true);
  const [from, setFrom] = useState(0);
  const [count, setCount] = useState(-1);

  const addGroup = () => {
    vsf.navigateTo('/add_group');
  };
  const reqGroups = async () => {
    const res =
      (await vsf.services.InspectionTeamController_getPagedByInspectionTeamSimpleQto_75cd2c(
        {
          qto: {
            campusIdIs: currentCampus?.campusId,
            size: 20,
            from,
          },
        },
      )) as any;
    if (res.code === 200 || res.data) {
      setGroupList([...groupList, ...res.data.result]);
      setCount(res.data.count);
      setHasMore(res.data.result.length === 20);
      setFrom(from + 1);
    } else {
      setHasMore(false);
    }
  };
  const toDetail = (id) => () => {
    vsf.navigateTo(`/add_group?id=${id}`);
  };
  return (
    <>
      <NavBar
        children="分组管理"
        backArrow={true}
        onBack={() => vsf.goBack()}
      />
      <Box className="page-grouping">
        <div className="list">
          {groupList.map((item) => {
            return (
              <div key={item.id} className="list-group">
                <div className="name">{item.teamName}</div>
                <div className="btn" onClick={toDetail(item.id)}>
                  编辑
                </div>
              </div>
            );
          })}
        </div>
        {count === 0 ? (
          <MyEmpty text="当前还未设置巡检分组" />
        ) : (
          <InfiniteScroll loadMore={reqGroups} hasMore={hasMore} />
        )}
        <FixedBtn
          onClick={addGroup}
          icon={<img src={addSvg}></img>}
          text="新增分组"
        />
      </Box>
    </>
  );
};

export default definePage(Grouping);

import vsf, { definePage } from '@vs/vsf-boot';
import { NavBar, Box, FormItem, Form, Input, Switch } from '@vs/vsf-mobile';
import React, { useState, useEffect } from 'react';
import FixedFormBtn from '@/modal/fixedFormBtn';
import TagFormItem from '@/modal/tagFormItem';
import CheckList from '@/modal/checkList';
import FormArea from '@/modal/formArea';
import FormTime from './components/formTime';
import FormMember from '@/modal/formItemMember';
import FormLabel from './components/formLabel';
import './index.less';
import addLabel from '@/assets/icons/addLabel.png';
import ConfirmMask from '@/modal/confirmMask';

const VistorCreate = (props) => {
  const { global } = props?.stores || {};
  const { userInfo, currentCampus } = global || {};
  const [readOnly, setReadOnly] = useState(false);
  const [visible, setVisible] = useState(false);
  const [visible1, setVisible1] = useState(false);
  const [visible2, setVisible2] = useState(false);
  const [visible3, setVisible3] = useState(-1);
  const [visible4, setVisible4] = useState(-1);
  const [regional, setRegional] = useState();
  const [maskVisible, setMaskVisible] = useState(false);
  const formAreaChange = (value) => {
    vsf.refs.form3768.setFieldValue(
      'regionalName',
      value?.regionalName || undefined,
    );
    vsf.refs.form3768.validateFields(['regionalName']);
    vsf.refs.form3768.setFieldValue('placeGroupBtoList', []);
    setRegional(value || undefined);
    setVisible(false);
  };

  const delGroup = async () => {
    if (!props.routes.query.id) return;
    const res =
      await vsf.services?.InspectionTeamController_deleteInspectionTeam_afe6c2?.(
        {
          btoParam: {
            id: props.routes.query.id,
            // campusId: regionData.campusId
          },
        },
      ); // TODO: 你可能需要整理返回数据格式
    if (res.code === 200) {
      vsf.showToast('删除成功');
      vsf.goBack();
    }
    return res?.data;
  };

  const reqGroupDetail = async (params) => {
    if (!props.routes.query.id) return;
    //
    // Tips: 以下是你选择的请求函数
    const res =
      await vsf.services?.InspectionTeamController_getInspectionTeamVoById_b90cb3?.(
        {
          id: props.routes.query.id,
        },
      ); // TODO: 你可能需要整理返回数据格式
    if (res.code === 200) {
      vsf.refs.form3768.setFieldsValue({
        ...res.data,
        labelGroupBtoList: res.data.labelGroupDtoList,
        placeGroupBtoList: res.data.placeGroupDtoList,
        inspectionGroupBtoList: res.data.inspectionGroupDtoList?.map(
          (item) => ({
            time: item.time,
            inspectionPersonnelBtoList: item.inspectionPersonnelDtoList,
          }),
        ),
      });
      setRegional({
        regionalName: res.data.regionalName,
        id: res.data.regionalId,
      });
    }
    return res?.data;
  };

  useEffect(() => {
    reqGroupDetail();
  }, []);

  return (
    <>
      <NavBar
        children={props.routes.query.id ? '分组详情' : '新增分组'}
        backArrow={true}
        onBack={() => vsf.goBack()}
      />
      <Box className="page-group-create">
        <Form
          mode="card"
          vsid="37696"
          id="form3768"
          layout="horizontal"
          requiredMarkStyle="none"
          hasFeedback={true}
          initialValues={{ inspectionGroupBtoList: [{}], isRemind: false }}
          style={{
            '--border-bottom': '1px solid #EBEFF4',
            '--prefix-width': '80px',
            '--text-align': 'right',
          }}
          className="groupForm"
          footer={false}
          onFinish={async (params) => {
            if (props.routes.query.id) {
              const res =
                await vsf.services?.InspectionTeamController_updateInspectionTeam_8c1a8d?.(
                  {
                    btoParam: {
                      id: props.routes.query.id,
                      ...params,
                      labelGroupBtoList: params.labelGroupBtoList?.map(
                        (item) => ({ labelId: item.labelId || item.id }),
                      ),
                      placeGroupBtoList: params.placeGroupBtoList?.map(
                        (item) => ({ placeId: item.placeId || item.id }),
                      ),
                      inspectionGroupBtoList:
                        params.inspectionGroupBtoList?.map((item) => ({
                          time: item.time.map((t) => t.key || t),
                          inspectionPersonnelBtoList:
                            item.inspectionPersonnelBtoList.map((p) => ({
                              personnelName: p.name || p.personnelName,
                              userId: p.userId,
                            })),
                        })),
                      campusId: currentCampus.campusId,
                      regionalId: regional.id,
                    },
                  },
                );
              if (res.code === 200) {
                vsf.goBack();
                vsf.showToast('提交成功');
              }
            } else {
              const res =
                await vsf.services?.InspectionTeamController_createInspectionTeam_30a69c?.(
                  {
                    btoParam: {
                      ...params,
                      labelGroupBtoList: params.labelGroupBtoList?.map(
                        (item) => ({ labelId: item.id }),
                      ),
                      placeGroupBtoList: params.placeGroupBtoList?.map(
                        (item) => ({ placeId: item.id }),
                      ),
                      inspectionGroupBtoList:
                        params.inspectionGroupBtoList?.map((item) => ({
                          time: item.time.map((t) => t.key),
                          inspectionPersonnelBtoList:
                            item.inspectionPersonnelBtoList.map((p) => ({
                              personnelName: p.name,
                              userId: p.userId,
                            })),
                        })),
                      campusId: currentCampus.campusId,
                      regionalId: regional.id,
                    },
                  },
                );
              if (res.code === 200) {
                vsf.goBack();
                vsf.showToast('提交成功');
              }
            }
          }}
        >
          <Form.Header>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;分组设置</Form.Header>
          <div className="form-box">
            <FormItem
              name="teamName"
              label="分组名称"
              rules={[
                { required: true, message: '请输入分组名称' },
                { maxLength: 10, message: '分组名称最多十个字' },
              ]}
            >
              <Input maxLength={10} placeholder="请输入" />
            </FormItem>
            <FormItem
              name="regionalName"
              label="区域名称"
              required={true}
              rules={[{ required: true, message: '请选择区域名称' }]}
              onClick={() => setVisible(true)}
              className="area-form"
            >
              <Input readOnly placeholder="请选择" />
            </FormItem>
            <TagFormItem
              name="placeGroupBtoList"
              label="对应场所"
              required={true}
              rules={[{ required: true, message: '请选择对应场所' }]}
              onClick={() => setVisible1(true)}
            >
              <CheckList
                regionalId={regional?.id}
                visible={visible1}
                onClose={() => setVisible1(false)}
              />
            </TagFormItem>
            <TagFormItem
              name="labelGroupBtoList"
              label="对应标签"
              required={true}
              className="last-form-item"
              rules={[{ required: true, message: '请选择对应标签' }]}
              onClick={() => setVisible2(true)}
            >
              <FormLabel
                visible={visible2}
                onClose={() => setVisible2(false)}
              />
            </TagFormItem>
          </div>
          <Form.Header></Form.Header>
          <Form.Array
            name="inspectionGroupBtoList"
            onAdd={(operation) => operation.add()}
            renderHeader={({ index }, { remove }) => (
              <>
                &nbsp;&nbsp;&nbsp;&nbsp;
                {index === 0 ? '人员管理' : null}
              </>
            )}
          >
            {(fields, { add, remove }) =>
              fields.map(({ index }) => (
                <>
                  <div className="form-box form-box1">
                    <TagFormItem
                      name={[index, 'time']}
                      label="巡检时间"
                      required={true}
                      rules={[{ required: true, message: '请选择巡检时间' }]}
                      onClick={() => setVisible3(index)}
                    >
                      <FormTime
                        visible={visible3 === index}
                        onClose={() => setVisible3(-1)}
                      />
                    </TagFormItem>
                    <TagFormItem
                      name={[index, 'inspectionPersonnelBtoList']}
                      label="巡检人员"
                      required={true}
                      rules={[{ required: true, message: '请选择巡检人员' }]}
                      onClick={() => setVisible4(index)}
                      className="last-form-item"
                    >
                      <FormMember
                        visible={visible4 === index}
                        onClose={() => setVisible4(-1)}
                      />
                    </TagFormItem>
                    {fields.length > 1 ? (
                      <div className="del-box">
                        <div className="line"></div>
                        <div className="delbtn" onClick={() => remove(index)}>
                          删除
                        </div>
                      </div>
                    ) : null}
                  </div>
                  {index === fields.length - 1 ? (
                    <div className="add">
                      <div className="add-btn" onClick={add}>
                        <img src={addLabel} alt="" />
                        <span>新增一项</span>
                      </div>
                    </div>
                  ) : null}
                </>
              ))
            }
          </Form.Array>
          <Form.Header></Form.Header>
          <div className="form-box3">
            <FormItem
              name="isRemind"
              label="巡检提醒"
              childElementPosition="right"
              className="last-form-item required-form"
              valuePropName="checked"
            >
              <Switch />
            </FormItem>
          </div>
        </Form>
        {props.routes.query.id ? (
          <FixedFormBtn
            onConfirm={() => vsf.refs.form3768.submit()}
            restText="删除"
            onReset={() => setMaskVisible(true)}
          />
        ) : !readOnly ? (
          <FixedFormBtn
            onConfirm={() => vsf.refs.form3768.submit()}
            onReset={() => vsf.refs.form3768.resetFields()}
          />
        ) : null}
        <FormArea
          defaultValue={regional?.id}
          getInitArea={(value) => {
            setRegional(value);
            vsf.refs.form3768.setFieldValue(
              'regionalName',
              value?.regionalName || undefined,
            );
            vsf.refs.form3768.validateFields(['regionalName']);
          }}
          visible={visible}
          onClose={() => setVisible(false)}
          onOk={formAreaChange}
        />
        <ConfirmMask
          visible={maskVisible}
          text="确定删除该分组吗？"
          onConfirm={delGroup}
          onMaskClick={() => setMaskVisible(false)}
        />
      </Box>
    </>
  );
};
export default definePage(VistorCreate);

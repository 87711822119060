import './index.less';

import vsf from '@vs/vsf-boot';
import { Checkbox, Space } from '@vs/vsf-mobile';
import React, { useEffect, useRef, useState } from 'react';

import collapseDown from '@/assets/icons/collapse-down.png';
import check from '@/assets/images/check.png';
import check1 from '@/assets/images/check1.png';
import Deltag from '@/modal/delTag';
import MyPopup from '@/modal/myPopup';
import { TagListInput } from '@/modal/tagFormItem';
interface FormTimeProps {
  visible?: boolean;
  value?: LabelItem[];
  onChange?: (value: LabelItem[]) => void;
  onClose: () => void;
}
interface LabelItem {
  id: number;
  createdAt: string;
  labelName: string;
  labelId?: number;
}
const FormTime: React.FC<FormTimeProps> = ({
  visible,
  onClose,
  value = [],
  onChange,
}) => {
  const [labels, setLabels] = useState<LabelItem[]>([]);
  const [collapse, setCollapse] = useState(false);
  const [showCollapse, setShowCollapse] = useState(false);
  const [labelValue, setLabelValue] = useState<LabelItem[]>(value);
  // 创建一个ref来存储DOM元素的引用
  const myRef = useRef<HTMLDivElement | null>(null);
  // if (!value || !onChange) return null;
  const removeTag = (key) => {
    setLabelValue(labelValue.filter((v) => v.id !== key));
  };
  const reqLabels = async () => {
    const res =
      (await vsf.services.RegionalManagementController_getPagedByLabelInfoPageQto_25249d(
        {
          qto: {
            campusIdIs: vsf.stores.global.currentCampus?.campusId,
            from: 0,
            size: 100,
          },
        },
      )) as any;
    if (res.code === 200 && res.data) {
      setLabels(res.data.result);
    }
  };
  useEffect(() => {
    reqLabels();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    if (value && value.length && labels) {
      setLabelValue(
        labels.filter((d) =>
          value.find((k) => k.labelId === d.id || k.id === d.id),
        ),
      );
    }
  }, [value, labels]);
  useEffect(() => {
    // 当组件挂载后，ref.current将指向相关的DOM节点，可以获取其高度
    if (myRef.current) {
      const height = myRef.current.clientHeight;
      setShowCollapse(height > 34);
    }
  }, [labelValue]); // 空依赖数组确保effect只在挂载时运行

  return (
    <>
      <TagListInput
        value={value?.map(
          (item) =>
            labels.find((t) => t.id === item.id || t.id === item.labelId)
              ?.labelName || '',
        )}
      />
      <MyPopup
        title="选择标签"
        visible={visible}
        onClose={onClose}
        onOk={() => {
          onChange && onChange(labelValue);
          onClose && onClose();
        }}
      >
        <div className="form-time-content">
          {labelValue.length ? (
            <div className="tag-list-box">
              <div className={!collapse ? 'time-tag-list1' : undefined}>
                <div ref={myRef}>
                  <Space
                    style={{ '--gap': '11px' }}
                    direction="horizontal"
                    wrap
                  >
                    {labelValue.map((item) => {
                      return (
                        <Deltag
                          key={item.id}
                          title={item.labelName}
                          onRemove={() => removeTag(item.id)}
                        />
                      );
                    })}
                  </Space>
                </div>
              </div>
              {showCollapse ? (
                <div
                  onClick={() => setCollapse(!collapse)}
                  className={`collapse`}
                >
                  {collapse ? '收起' : '展开'}
                  <img
                    className={`${collapse ? 'up' : 'down'}`}
                    src={collapseDown}
                    alt=""
                  />
                </div>
              ) : null}
            </div>
          ) : null}
          <div className="time-list">
            <Space direction="vertical">
              <Checkbox
                style={{ '--icon-size': '18px' }}
                icon={(checked, indeterminate) => {
                  return (
                    <img
                      className="check-icon"
                      src={checked ? check1 : check}
                    ></img>
                  );
                }}
                indeterminate={
                  labelValue.length > 0 && labelValue.length < labels.length
                }
                checked={labelValue.length === labels.length}
                onChange={(checked) => {
                  if (checked) {
                    setLabelValue(labels);
                  } else {
                    setLabelValue([]);
                  }
                }}
              >
                <span className="check-all">全选</span>
              </Checkbox>
              <Checkbox.Group
                value={labelValue.map((item) => item.id)}
                onChange={(v) => {
                  setLabelValue(
                    labels.filter((item) => v.find((day) => item.id === day)),
                  );
                }}
              >
                <Space direction="vertical">
                  {labels.map((item) => (
                    <Checkbox
                      key={item.id}
                      style={{ '--icon-size': '18px' }}
                      icon={(checked, indeterminate) => {
                        return (
                          <img
                            className="check-icon"
                            src={checked ? check1 : check}
                          ></img>
                        );
                      }}
                      value={item.id}
                    >
                      <span className="check-label">{item.labelName}</span>
                    </Checkbox>
                  ))}
                </Space>
              </Checkbox.Group>
            </Space>
          </div>
        </div>
      </MyPopup>
    </>
  );
};
export default FormTime;

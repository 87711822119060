import { createServices } from '@vs/vsf-boot';
export default createServices({
  VisitorController_getByIdAndCampusId_6b9aad: {
    method: 'post',
    url: '/api/visitor-center-info/get-by-id',
    parameterFomatter: (data?: { var: VisitorCenterIdCampusIdEoB9216E }) =>
      data,
    responseFormatter: (_, __, data?: VisitorIdVo1B752B) => data,
  },
});

export const STORAGE_KEY_PREFIX = 'safe-campus';
export const APP_KEY = 'varl4gyrmc';
export enum placeType {
  ADMINISTRATION_TEAM = '办公区域',
  TEAM_AREA = '班级区域',
  COMMON_ALREADY = '公共区域',
  OTHER_AREA = '其他区域',
}
export const LOGIN_URL =
  'https://sso.schbrain.com/sso/oauth2/authorize?response_type=code';
export const LOGIN_TEXT_URL =
  'https://sso-dev.schbrain.com/sso/oauth2/authorize?response_type=code';
export default {};

import './index.less';

import vsf, { definePage } from '@vs/vsf-boot';
import { Box, InfiniteScroll, NavBar } from '@vs/vsf-mobile';
import { CheckCircleFill, CheckCircleOutline } from 'antd-mobile-icons';
import React, { useEffect, useState } from 'react';

import addSvg from '@/assets/icons/add.svg';
import FixedBtn from '@/modal/fixedBtn';
import MyEmpty from '@/modal/myEmpty';
interface Campus {
  campusId: number;
  name: string;
}
const Campus: React.FC = (props: any) => {
  const { global } = props?.stores || {};
  const { userInfo, currentCampus, setCurrentCampus, campusList } =
    global || {};
  const [checkCampus, setCheckCampus] = useState(currentCampus || {});
  const onChenge = () => {
    setCurrentCampus(checkCampus);
    vsf.goBack();
  };
  return (
    <>
      <NavBar children="校区" backArrow={true} onBack={() => vsf.goBack()} />
      <Box className="page-campus">
        <div className="list">
          {campusList.map((item) => {
            return (
              <div key={item.campusId} className="list-group">
                <div className="name">{item.name}</div>
                <div onClick={() => setCheckCampus(item)}>
                  {item.campusId === checkCampus.campusId ? (
                    <CheckCircleFill
                      style={{ color: '#499AFF', fontSize: '20px' }}
                    />
                  ) : (
                    <CheckCircleOutline
                      style={{ color: '#8FA0B8', fontSize: '20px' }}
                    />
                  )}
                </div>
              </div>
            );
          })}
        </div>
        {campusList.length === 0 && <MyEmpty text="当前暂无数据" />}
        <FixedBtn onClick={onChenge} text="确定" />
      </Box>
    </>
  );
};

export default definePage(Campus);

import './index.less';

import vsf from '@vs/vsf-boot';
import React from 'react';

import empty from '@/assets/images/empty.png';

interface EmptyProps {
  imgUrl?: string;
  text: string;
  style?: React.CSSProperties | undefined;
}
const FixedBtn: React.FC<EmptyProps> = ({ imgUrl, text, style }) => {
  return (
    <div style={style} className="my-empty">
      <img src={imgUrl || empty} alt="" />
      <div className="empty-text">{text}</div>
    </div>
  );
};

export default FixedBtn;

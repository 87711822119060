import './index.less';

import vsf from '@vs/vsf-boot';
import React, { ReactElement } from 'react';
interface FixedBtnProps {
  onClick: () => void; // onClick 不接受任何参数并且没有返回值
  icon?: ReactElement | undefined; // icon 是一个字符串类型的路径
  text: string;
}
const FixedBtn: React.FC<FixedBtnProps> = ({ onClick, icon, text }) => {
  return (
    <div className="bottom-btn-box">
      <div className="btn" onClick={onClick}>
        {icon}
        <span>{text}</span>
      </div>
    </div>
  );
};

export default FixedBtn;

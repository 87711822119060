import vp2041002 from './vp2041002';
import vp2041102 from './vp2041102';
import vp2041205 from './vp2041205';
import vp2041301 from './vp2041301';
import vp2041603 from './vp2041603';
import vp2043004 from './vp2043004';

export default {
  ...vp2041002,
  ...vp2041102,
  ...vp2041205,
  ...vp2041301,
  ...vp2041603,
  ...vp2043004,
};

import { RouteConfig } from '@vs/vsf-boot';
import { AppOutline, UserOutline } from '@vs/vsf-mobile-icons';
import React from 'react';

import InspectionAnalysisIcon from '@/assets/icons/InspectionAnalysis.png';
import InspectionAnalysisChoose from '@/assets/icons/InspectionAnalysisChoose.png';
import InspectionRecordIcon from '@/assets/icons/InspectionRecord.png';
import InspectionRecordChoose from '@/assets/icons/InspectionRecordChoose.png';
import userIcon1 from '@/assets/icons/userIcon1.png';
import userIcon2 from '@/assets/icons/userIcon2.png';
import visitorIcon from '@/assets/icons/visitorIcon.png';
import visitorIcon1 from '@/assets/icons/visitorIcon1.png';
import Root from '@/layouts';
import AddGroup from '@/pages/addGroup';
import AddPlace from '@/pages/addPlace';
import AddRegion from '@/pages/addRegion';
import Campus from '@/pages/campus';
import Grouping from '@/pages/grouping';
import InspectionAnalysis from '@/pages/InspectionAnalysis';
import InspectionAnalysisDetail from '@/pages/inspectionAnalysisDetail';
import InspectionRecord from '@/pages/InspectionRecord';
import Index from '@/pages/InspectionRecordDetail';
import InspectionRecordDetail from '@/pages/InspectionRecordDetail';
import InspectionRecordForm from '@/pages/inspectionRecordForm';
import Label from '@/pages/label';
import Login from '@/pages/Login';
import OauthLogin from '@/pages/oauthLogin';
import Personal from '@/pages/personal';
import Region from '@/pages/region';
import Visitor from '@/pages/visitor';
import VisitorCode from '@/pages/visitorCode';
import VistorCreate from '@/pages/vistorCreate';

import pageRoutes from './routes';

export type RouteWithTab = Omit<RouteConfig, 'path' | 'routes'> & {
  // tab属性自动识别为tab页
  tab?: {
    icon?: React.ReactNode;
    title?: React.ReactNode;
  };
  routes?: RouteWithTab[] | undefined;
  path: string;
  isLogined?: boolean;
  isAdmin?: boolean;
};

const routes: RouteWithTab[] = [
  {
    path: '/',
    component: Root,
    redirect: '/InspectionRecord',
    routes: [
      {
        path: '/InspectionRecord',
        exact: true,
        component: InspectionRecord,
        tab: {
          icon: (active: boolean) =>
            active ? (
              <img src={InspectionRecordChoose} className="tabBarIcon"></img>
            ) : (
              <img src={InspectionRecordIcon} className="tabBarIcon"></img>
            ),

          title: '巡检记录',
        },
      },
      {
        path: '/InspectionAnalysis',
        exact: true,
        component: InspectionAnalysis,
        tab: {
          icon: (active: boolean) =>
            active ? (
              <img src={InspectionAnalysisChoose} className="tabBarIcon"></img>
            ) : (
              <img src={InspectionAnalysisIcon} className="tabBarIcon"></img>
            ),
          title: '巡检分析',
        },
      },
      {
        path: '/InspectionAnalysisDetail',
        exact: true,
        component: InspectionAnalysisDetail,
      },
      {
        path: '/InspectionRecordForm',
        exact: true,
        component: InspectionRecordForm,
      },
      {
        path: '/InspectionRecordDetail',
        exact: true,
        component: InspectionRecordDetail,
        isAdmin: false,
      },
      {
        path: '/visitor',
        exact: true,
        component: Visitor,
        tab: {
          icon: (active: boolean) =>
            active ? (
              <img src={visitorIcon} className="tabBarIcon"></img>
            ) : (
              <img src={visitorIcon1} className="tabBarIcon"></img>
            ),
          title: '访客记录',
        },
      },
      {
        path: '/visitor_create',
        exact: true,
        component: VistorCreate,
        isAdmin: false,
        isLogined: false,
      },
      {
        path: '/visitor_code',
        exact: true,
        component: VisitorCode,
      },
      {
        path: '/personal',
        exact: true,
        component: Personal,
        tab: {
          icon: (active: boolean) =>
            active ? (
              <img src={userIcon2} className="tabBarIcon"></img>
            ) : (
              <img src={userIcon1} className="tabBarIcon"></img>
            ),
          title: '个人中心',
        },
        isAdmin: false,
        isLogined: false,
      },
      {
        path: '/label',
        exact: true,
        component: Label,
      },
      {
        path: '/grouping',
        exact: true,
        component: Grouping,
      },
      {
        path: '/region',
        exact: true,
        component: Region,
      },
      {
        path: '/add_region',
        exact: true,
        component: AddRegion,
      },
      {
        path: '/add_group',
        exact: true,
        component: AddGroup,
      },
      {
        path: '/add_place',
        exact: true,
        component: AddPlace,
      },
      {
        path: '/campus',
        exact: true,
        component: Campus,
      },
      {
        path: '/oauth_login',
        exact: true,
        component: OauthLogin,
      },
      {
        path: '/login',
        exact: true,
        component: Login,
        isAdmin: false,
        isLogined: false,
      },
      ...pageRoutes,
    ],
  },
];

export default routes;

import './index.less';

import vsf from '@vs/vsf-boot';
import { Checkbox, Space } from '@vs/vsf-mobile';
import React, { useEffect, useState } from 'react';

import check from '@/assets/images/check.png';
import check1 from '@/assets/images/check1.png';
import Deltag from '@/modal/delTag';
import MyPopup from '@/modal/myPopup';
import { TagListInput } from '@/modal/tagFormItem';
interface FormTimeProps {
  visible?: boolean;
  onClose: () => void;
  value?: any[];
  onChange?: (value: any[]) => void;
}
const daysOfWeek = [
  { key: 'MONDAY', value: '周一', sort: 1 },
  { key: 'TUESDAY', value: '周二', sort: 2 },
  { key: 'WEDNESDAY', value: '周三', sort: 3 },
  { key: 'THURSDAY', value: '周四', sort: 4 },
  { key: 'FRIDAY', value: '周五', sort: 5 },
  { key: 'SATURDAY', value: '周六', sort: 6 },
  { key: 'SUNDAY', value: '周日', sort: 7 },
];
const FormTime: React.FC<FormTimeProps> = ({
  visible,
  onClose,
  value = [],
  onChange,
}) => {
  const [timeValue, setTimeValue] = useState<any[]>([]);

  const removeTag = (key) => {
    setTimeValue(timeValue.filter((v) => v.key !== key));
  };
  /* eslint-disable */
  useEffect(() => {
    if (value && value.length) {
      setTimeValue(
        value.map((day) =>
          daysOfWeek?.find((k) => k.key === day || k?.key === day?.key),
        ),
      );
    }
  }, [value]);
  /* eslint-enable */
  return (
    <>
      <TagListInput
        value={value?.map(
          (item) =>
            item?.value || daysOfWeek.find((t) => t.key === item)?.value,
        )}
      />
      <MyPopup
        title="选择时间"
        visible={visible}
        onClose={onClose}
        onOk={() => {
          onChange && onChange(timeValue);
          onClose();
        }}
      >
        <div className="form-time-content">
          {timeValue.length ? (
            <div className="time-tag-list">
              <Space wrap style={{ '--gap': '20px' }}>
                {timeValue
                  .sort((a, b) =>
                    a.sort < b.sort ? -1 : a.sort > b.sort ? 1 : 0,
                  )
                  .map((item) => {
                    return (
                      <Deltag
                        key={item.key}
                        title={item.value}
                        onRemove={() => removeTag(item.key)}
                      />
                    );
                  })}
              </Space>
            </div>
          ) : null}
          <div className="time-list">
            <Space direction="vertical">
              <Checkbox
                style={{ '--icon-size': '18px' }}
                icon={(checked, indeterminate) => {
                  return (
                    <img
                      className="check-icon"
                      src={checked ? check1 : check}
                    ></img>
                  );
                }}
                indeterminate={
                  timeValue.length > 0 && timeValue.length < daysOfWeek.length
                }
                checked={timeValue.length === daysOfWeek.length}
                onChange={(checked) => {
                  if (checked) {
                    setTimeValue(daysOfWeek);
                  } else {
                    setTimeValue([]);
                  }
                }}
              >
                <span className="check-all">全选</span>
              </Checkbox>
              <Checkbox.Group
                value={timeValue.map((item) => item.key)}
                onChange={(v) => {
                  setTimeValue(
                    daysOfWeek.filter((item) =>
                      v.find((day) => item.key === day),
                    ),
                  );
                }}
              >
                <Space direction="vertical">
                  {daysOfWeek.map((item) => (
                    <Checkbox
                      key={item.key}
                      style={{ '--icon-size': '18px' }}
                      icon={(checked, indeterminate) => {
                        return (
                          <img
                            className="check-icon"
                            src={checked ? check1 : check}
                          ></img>
                        );
                      }}
                      value={item.key}
                    >
                      <span className="check-label">{item.value}</span>
                    </Checkbox>
                  ))}
                </Space>
              </Checkbox.Group>
            </Space>
          </div>
        </div>
      </MyPopup>
    </>
  );
};
export default FormTime;

import './index.less';

import vsf from '@vs/vsf-boot';
import { Input, Radio, Space } from '@vs/vsf-mobile';
import React, { useState } from 'react';

import { placeType } from '@/config';
import MyPopup from '@/modal/myPopup';

interface FormPlaceTypeProps {
  value: string;
  visible?: boolean;
  onClose?: () => void;
  onOk?: (params) => void;
  onChenge?: () => void;
}

const FormPlaceType: React.FC<FormPlaceTypeProps> = ({
  visible,
  onClose,
  onOk,
  value,
  onChenge,
}) => {
  const [palceTypeValue, setPlaceTypeValue] = useState(
    value || 'ADMINISTRATION_TEAM',
  );
  return (
    <>
      <Input
        value={placeType[value]}
        onChange={onChenge}
        readOnly
        placeholder="请选择场所类型"
      />
      <MyPopup
        title="选择场所类型"
        visible={visible}
        onClose={onClose}
        onOk={() => onOk && onOk(palceTypeValue)}
      >
        <div className="area-content">
          <Radio.Group
            onChange={(val) => setPlaceTypeValue(val as string)}
            value={palceTypeValue}
          >
            <Space direction="vertical">
              {Object.keys(placeType).map((item) => {
                return (
                  <Radio key={item} value={item}>
                    {placeType[item]}
                  </Radio>
                );
              })}
            </Space>
          </Radio.Group>
        </div>
      </MyPopup>
    </>
  );
};
export default FormPlaceType;

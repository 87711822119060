import './index.less';

import vsf, { definePage } from '@vs/vsf-boot';
import {
  Box,
  List,
  NavBar,
  Modal,
  Input,
  ImageViewer,
  InfiniteScroll,
} from '@vs/vsf-mobile';
import { MessageOutline } from 'antd-mobile-icons';
import React, { useEffect, useState } from 'react';

import comment from '@/assets/icons/comment.png';
import btn_up from '@/assets/images/btn_up.png';
import recordLabel from '@/assets/icons/recordLabel.png';
import deleteImg from '@/assets/icons/delete.png';
import MyEmpty from '@/modal/myEmpty';

const InspectionRecordDetail = (props) => {
  const { global } = props?.stores || {};
  const {
    userInfo,
  } = global || {};
  const [detailData, setDetailData] = useState([]);
  const [hasMore, setHasMore] = useState(true);
  const [from, setFrom] = useState(0);
  const [count, setCount] = useState(-1);
  const [ModalVisible, setModalVisible] = useState(false);
  const [DeleteVisible, setDeleteVisible] = useState(false);

  const [visible, setVisible] = useState(false);
  const [value, setValue] = useState();
  const [recordId, setRecordId] = useState();
  const [imgValue, setImgValue] = useState();
  const [delteItem, setDeleteItem] = useState();

  const handleGetRecordDetail = async (reload) => {
    if(!userInfo.userId) return;
    const res =
      await vsf.services?.InspectionCenterInfoController_getPagedByInspectionRecordQto_d2cadd?.(
        {
          qto: {
            placeIdIs: props?.routes?.query?.placeId,
            size: reload ? from * 10 : 10,
            from: reload ? 0 : from * 10 ?? 0,
          },
        },
      );

    if (res && res?.code === 200) {
      setDetailData(
        reload ? res.data.result : [...detailData, ...res.data.result],
      );
      setCount(res.data.count);
      setHasMore(
        reload
          ? from * 10 === res.data.result.length
          : res.data.result.length === 10,
      );
      !reload && setFrom(from + 1);
      if (reload && res.data.result?.length <= 0) {
        vsf?.showToast('当前无巡检记录');
        setTimeout(() => {
          vsf?.navigateTo('./InspectionRecord');
        }, 1500);
      }
    }
  };

  const handleComment = async () => {
    if (value) {
      const res =
        await vsf.services?.InspectionCenterInfoController_createComment_e93231?.(
          {
            btoParam: {
              commentName: vsf.stores.global?.userInfo?.name,
              comment: value,
              recordId: recordId,
            },
          },
        );

      if (res && res?.code === 200) {
        setModalVisible(false);
        setValue(undefined);
        vsf.showToast('发表成功');
        handleGetRecordDetail(true);
      }
    } else {
      vsf.showToast('请填写评论');
    }
  };

  const handleDeleteRecord = async () => {
    console.log(delteItem, 'item');
    const res =
      await vsf.services?.InspectionCenterInfoController_deleteInspectionRecord_a331a8?.(
        {
          btoParam: {
            id: delteItem?.id,
          },
        },
      );
    if (res && res?.code === 200) {
      setDeleteVisible(false);
      vsf.showToast('删除成功');
      handleGetRecordDetail(true);
    }
  };

  const scrollTop = () => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth',
    });
  };

  const showScrollBtn = () => {
    // 获取按钮元素
    const myButton = document.getElementById('myButton');
    if (myButton) {
      // 隐藏按钮
      myButton.style.display = 'none';
      // 设置当页面滚动超过100px时显示按钮
      const toggleButtonVisibility = () => {
        if (window.scrollY > 200) {
          myButton.style.display = 'block';
        } else {
          myButton.style.display = 'none';
        }
      };
      // 监听页面滚动事件
      window.addEventListener('scroll', toggleButtonVisibility);
    }
  };

  useEffect(() => {
    showScrollBtn();
  }, []);
  if(!userInfo.userId) return null;
  return (
    <>
      <NavBar
        children="巡检记录"
        onBack={() => vsf.history.replace('/')}
        backArrow={vsf.stores.global?.isAdmin}
      />
      <Box>
        <div className="InspectionRecordDetailContainer">
          <div className="InspectionRecordDetailContainer-top">
            <div className="InspectionRecordDetailContainer-top__left">
              <div className="InspectionRecordDetailContainer-top__left__class">
                {detailData?.[0]?.place ?? ''}
              </div>
              <div className="InspectionRecordDetailContainer-top__left__line"></div>
            </div>

            {vsf.stores.global?.isAdmin && (
              <>
                <div
                  className="InspectionRecordDetailContainer-top__right"
                  onClick={() => {
                    vsf?.navigateTo(
                      `./InspectionRecordForm?areaId=${detailData?.[0]?.areaId}&type=add&recordId=${detailData?.[0]?.id}`,
                    );
                  }}
                >
                  新增巡检记录
                </div>
              </>
            )}
          </div>
          <div className="InspectionRecordDetailContainer-con">
            <div style={{ paddingTop: '.1rem' }}></div>
            {detailData?.map((item, index) => {
              return (
                <>
                  <div className="InspectionRecordDetailContainer-con__item">
                    <div className="InspectionRecordDetailContainer-con__item__top">
                      <div className="InspectionRecordDetailContainer-con__item__top__left">
                        <div className="InspectionRecordDetailContainer-con__item__top__left__icon">
                          <img src={recordLabel} alt="" />
                        </div>
                        <div className="InspectionRecordDetailContainer-con__item__top__left__name">
                          记录标签
                        </div>
                      </div>

                      {vsf.stores.global?.isAdmin ? (
                        <>
                          <div
                            style={{
                              display: 'flex',
                            }}
                          >
                            <div
                              className="InspectionRecordDetailContainer-con__item__top__edit"
                              onClick={() => {
                                vsf?.navigateTo(
                                  `./InspectionRecordForm?areaId=${detailData?.[0]?.areaId}&type=edit&recordId=${item.id}`,
                                );
                              }}
                            >
                              修改
                            </div>
                            <div
                              className="InspectionRecordDetailContainer-con__item__top__delete"
                              onClick={() => {
                                setDeleteVisible(true);
                                setDeleteItem(item);
                              }}
                            >
                              <img src={deleteImg} alt="" />
                            </div>
                          </div>
                        </>
                      ) : (
                        <>
                          <div className="InspectionRecordDetailContainer-con__item__top__right">
                            提交时间：{item?.submitTime ?? '-'}
                          </div>
                        </>
                      )}
                    </div>
                    <div className="InspectionRecordDetailContainer-con__item__con">
                      {item?.inspectionRecordLabelDtoList?.map((ele, cur) => {
                        return (
                          <>
                            <div
                              className="InspectionRecordDetailContainer-con__item__con__cur"
                              key={cur}
                            >
                              {ele?.labelName ?? ''}
                            </div>
                          </>
                        );
                      })}
                    </div>
                    <div className="InspectionRecordDetailContainer-con__item__message">
                      {item?.review ?? ''}
                    </div>
                    <div className="InspectionRecordDetailContainer-con__item__imgBox">
                      {JSON.parse(item?.reviewImg ?? '[]')?.map((sle, key) => {
                        return (
                          <>
                            <div
                              className="InspectionRecordDetailContainer-con__item__imgBox__img"
                              key={key}
                            >
                              <img
                                src={sle.url}
                                alt=""
                                onClick={() => {
                                  setVisible(true);
                                  setImgValue(sle.url);
                                }}
                              />
                            </div>
                          </>
                        );
                      })}
                    </div>

                    <div className="InspectionRecordDetailContainer-con__item__comment">
                      <div className="InspectionRecordDetailContainer-con__item__comment__time">
                        {vsf.stores.global?.isAdmin && (
                          <>提交时间：{item?.submitTime ?? '-'}</>
                        )}
                      </div>

                      <div className="InspectionRecordDetailContainer-con__item__comment__icon">
                        <img
                          src={comment}
                          alt=""
                          onClick={() => {
                            setModalVisible(true);
                            setRecordId(item?.id);
                          }}
                        />
                      </div>
                    </div>

                    <div className="InspectionRecordDetailContainer-con__item__commentDetail">
                      {item?.commentDtoList?.map((item, index) => {
                        return (
                          <>
                            <div className="InspectionRecordDetailContainer-con__item__commentDetail__item">
                              {item?.commentName}：{item?.comment}
                            </div>
                          </>
                        );
                      })}
                    </div>
                  </div>
                </>
              );
            })}
          </div>

          <Modal
            visible={ModalVisible}
            disableBodyScroll={true}
            closeOnMaskClick
            onClose={() => {
              setModalVisible(false);
            }}
          >
            <div style={{ display: 'flex' }}>
              <Input
                style={{
                  '--font-size': '14px',
                }}
                className="commentInput"
                clear
                value={value}
                onChange={(v) => {
                  setValue(v);
                }}
                placeholder="请输入评论"
              />
              <div
                className="commentBtn"
                onClick={() => {
                  handleComment();
                }}
              >
                发表
              </div>
            </div>
          </Modal>

          <Modal
            visible={DeleteVisible}
            disableBodyScroll={true}
            closeOnMaskClick
            onClose={() => {
              setDeleteVisible(false);
            }}
          >
            <div className="deleteModal">
              <div className="deleteModal__title">确定删除该项纪录吗？</div>
              <div className="deleteModal__btn">
                <div
                  className="deleteModal__btn__left"
                  onClick={() => {
                    setDeleteVisible(false);
                  }}
                >
                  取消
                </div>
                <div
                  className="deleteModal__btn__right"
                  onClick={() => {
                    handleDeleteRecord();
                  }}
                >
                  确定
                </div>
              </div>
            </div>
          </Modal>

          <ImageViewer
            classNames={{
              mask: 'customize-mask',
              body: 'customize-body',
            }}
            image={imgValue}
            visible={visible}
            onClose={() => {
              setVisible(false);
            }}
          />
        </div>

        {count === 0 ? (
          <MyEmpty text="当前无巡检记录" />
        ) : (
          <InfiniteScroll loadMore={handleGetRecordDetail} hasMore={hasMore} />
        )}
        <div id="myButton" className="back-up" onClick={scrollTop}>
          <img src={btn_up} alt="" />
        </div>
      </Box>
    </>
  );
};

export default definePage(InspectionRecordDetail);

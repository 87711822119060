import './index.less';

import vsf from '@vs/vsf-boot';
import { Popup } from '@vs/vsf-mobile';
import React, { ReactElement } from 'react';

interface CheckListProps {
  title?: string;
  visible?: boolean;
  onClose?: () => void;
  onOk?: (...values: any[]) => void;
  children?: ReactElement;
}

const MyFormItem: React.FC<CheckListProps> = ({
  title,
  visible,
  onClose,
  onOk,
  children,
}) => {
  return (
    <Popup
      visible={visible}
      onMaskClick={onClose}
      bodyStyle={{
        borderTopLeftRadius: '14px',
        borderTopRightRadius: '14px',
        minHeight: '40vh',
      }}
      getContainer={() => document.getElementById('layout_main') as HTMLElement}
      className="my-popup"
    >
      <div className="popup-header">
        <div className="close" onClick={onClose}>
          取消
        </div>
        <div className="title">{title}</div>
        <div className="ok" onClick={onOk}>
          确定
        </div>
      </div>
      <div className="popup-content">{children}</div>
    </Popup>
  );
};
export default MyFormItem;

import './index.less';

import vsf from '@vs/vsf-boot';
import { Checkbox, Grid, NavBar, Popup, Space } from '@vs/vsf-mobile';
import { flatMap } from 'lodash';
import React, { useEffect, useState } from 'react';

import check from '@/assets/images/check.png';
import check1 from '@/assets/images/check1.png';
import xiaoqu from '@/assets/images/xiaoqu.png';
import { placeType } from '@/config';
import MyEmpty from '@/modal/myEmpty';
import { TagListInput } from '@/modal/tagFormItem';
interface CheckListProps {
  value?: PlaceInfoDtoListProps[];
  onChange?: (value: PlaceInfoDtoListProps[]) => void;
  visible?: boolean;
  onClose: () => void;
  defaultValue: string;
  regionalId: string;
  isMul: boolean;
}
interface PlaceInfoDtoListProps {
  id: number;
  placeManDtoList: any[];
  placeName: string;
  regionalId: number;
  placeType: string;
  placeId?: number;
}
const placeTypeFilter = (data: PlaceInfoDtoListProps[], key) => {
  return data?.filter((item) => item.placeType === key);
};

const getflatMap = (obj) => {
  const arr = Object.keys(obj).map((key) => obj[key]);
  return flatMap(arr);
};
const placeType1 = {};
Object.keys(placeType).map((item) => (placeType1[item] = []));

const CheckList: React.FC<CheckListProps> = ({
  value = [],
  onChange,
  visible,
  onClose,
  regionalId,
  defaultValue,
  isMul,
}) => {
  const [placeTypeObj, setPlaceTypeObj] = useState({ ...placeType1 });
  const [placeValueObj, setPlaceValueObj] = useState({ ...placeType1 });
  const [placeMul, setPlaceMul] = useState<PlaceInfoDtoListProps[]>([]);
  const [loading, setLoading] = useState<boolean>(false);

  const getPlaceValueObj = (value) => {
    const obj = { ...placeType1 };
    Object.keys(placeType1).map(
      (key) => (obj[key] = placeTypeFilter(value, key)),
    );
    return obj;
  };
  const reqPlace = async () => {
    const res =
      (await vsf.services.RegionalManagementController_getListPlaceInfoIdVoByRegionalId_24cfcb(
        {
          regionalId,
        },
      )) as any;
    if (res.code === 200 || res.data) {
      const obj = { ...placeTypeObj };
      Object.keys(placeType1).map((key) => {
        obj[key] = placeTypeFilter(res.data, key) || [];
      });
      setPlaceTypeObj({ ...obj });
      setLoading(true);
    }
  };

  const onOk = () => {
    if (isMul) {
      onChange && onChange(placeMul);
    } else {
      onChange && onChange(getflatMap(placeValueObj));
    }
    onClose && onClose();
  };
  useEffect(() => {
    if (regionalId) {
      reqPlace();
      setPlaceMul([]);
      setPlaceValueObj(getPlaceValueObj(value));
    }
    console.log(getflatMap(placeTypeObj));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [regionalId]);

  useEffect(() => {
    if (value.length && loading) {
      if (isMul) {
        setPlaceMul(
          getflatMap(placeTypeObj)?.filter((v) =>
            value.find((item) => v.id === item.id || v.id === item.placeId),
          ),
        );
      }
      if (
        getflatMap(placeTypeObj).length &&
        !getflatMap(placeValueObj).length
      ) {
        const obj = { ...placeType1 };
        Object.keys(placeType1).map((key) => {
          obj[key] = placeTypeObj[key]?.filter((v) =>
            value.find((item) => v.id === item.id || v.id === item.placeId),
          );
        });
        setPlaceValueObj({ ...obj });
        onChange && onChange(getflatMap(obj));
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value, loading, isMul]);

  return (
    <>
      <TagListInput
        value={value?.map(
          (item) =>
            getflatMap(placeTypeObj).find(
              (v) => v.id === item.id || v.id === item.placeId,
            )?.placeName || '',
        )}
      />
      <Popup
        position="right"
        className="tag-popup"
        visible={visible}
        onClose={onOk}
        getContainer={() =>
          document.getElementById('layout_main') as HTMLElement
        }
      >
        <div className="tag-popup-content">
          {/* <NavBar children="选择场所" backArrow={true} onBack={onOk} /> */}
          <div className="popup-header">
            <div className="close" onClick={onClose}>
              取消
            </div>
            <div className="title">选择场所</div>
            <div className="ok" onClick={onOk}>
              确定
            </div>
          </div>
          <div className="tag-list">
            {getflatMap(placeTypeObj).length ? (
              <Space
                className="check-list"
                style={{ width: '100%' }}
                direction="vertical"
              >
                <div className="tag-checked-all">
                  {isMul ? (
                    ''
                  ) : (
                    <>
                      <div className="line"></div>
                      <span>全选</span>
                      <Checkbox
                        /* eslint-disable */
                        indeterminate={
                          getflatMap(placeValueObj).length > 0 &&
                          getflatMap(placeValueObj).length <
                          getflatMap(placeTypeObj).length
                        }
                        /* eslint-enable */
                        checked={
                          getflatMap(placeValueObj).length ===
                          getflatMap(placeTypeObj).length
                        }
                        onChange={(checked) => {
                          console.log(
                            getflatMap(placeTypeObj),
                            getflatMap(placeValueObj),
                          );
                          if (checked) {
                            setPlaceValueObj(placeTypeObj);
                          } else {
                            setPlaceValueObj({ ...placeType1 });
                          }
                        }}
                      ></Checkbox>
                    </>
                  )}
                </div>
                <div className="tag-checkbox">
                  {Object.keys(placeTypeObj).map((key) => {
                    if (!placeTypeObj[key].length) return null;
                    return (
                      <div className="c_item" key={key}>
                        <div className="tag-head">
                          <img src={xiaoqu} alt="" />
                          <div className="tag-title">{placeType[key]}</div>
                        </div>

                        {isMul ? (
                          ''
                        ) : (
                          <>
                            <Checkbox
                              style={{ '--icon-size': '18px' }}
                              icon={(checked, indeterminate) => {
                                return (
                                  <img
                                    className="check-icon"
                                    src={checked ? check1 : check}
                                  ></img>
                                );
                              }}
                              /* eslint-disable */
                              indeterminate={
                                placeValueObj[key].length > 0 &&
                                placeValueObj[key].length <
                                placeTypeObj[key].length
                              }
                              checked={
                                placeValueObj[key].length ===
                                placeTypeObj[key].length
                              }
                              /* eslint-enable */
                              onChange={(checked) => {
                                if (checked) {
                                  setPlaceValueObj({
                                    ...placeValueObj,
                                    [key]: placeTypeObj[key],
                                  });
                                } else {
                                  setPlaceValueObj({
                                    ...placeValueObj,
                                    [key]: [],
                                  });
                                }
                              }}
                            >
                              <span className="check-all">全选</span>
                            </Checkbox>
                          </>
                        )}

                        <Checkbox.Group
                          value={
                            isMul
                              ? placeMul.map((item) => item.id)
                              : placeValueObj[key].map((item) => item.id)
                          }
                          onChange={(v) => {
                            if (isMul) {
                              if (v?.length > 0) {
                                setPlaceMul(
                                  [v?.[v.length - 1]].map((item) =>
                                    placeTypeObj[key].find(
                                      (place) => item === place.id,
                                    ),
                                  ) as PlaceInfoDtoListProps[],
                                );
                              } else {
                                setPlaceMul([]);
                              }
                            } else {
                              setPlaceValueObj({
                                ...placeValueObj,
                                [key]: v.map((item) =>
                                  placeTypeObj[key].find(
                                    (place) => item === place.id,
                                  ),
                                ) as PlaceInfoDtoListProps[],
                              });
                            }
                          }}
                        >
                          <Grid columns={2} gap={12}>
                            {placeTypeObj[key].map((item) => (
                              <Grid.Item key={item.id}>
                                <Checkbox
                                  style={{ '--icon-size': '18px' }}
                                  icon={(checked, indeterminate) => {
                                    return (
                                      <img
                                        className="check-icon"
                                        src={checked ? check1 : check}
                                      ></img>
                                    );
                                  }}
                                  value={item.id}
                                >
                                  <span className="check-label">
                                    {item.placeName}
                                  </span>
                                </Checkbox>
                              </Grid.Item>
                            ))}
                          </Grid>
                        </Checkbox.Group>
                      </div>
                    );
                  })}
                </div>
              </Space>
            ) : (
              <MyEmpty text="当前区域暂无场所" />
            )}
          </div>
        </div>
      </Popup>
    </>
  );
};
export default CheckList;

import './index.less';

import vsf from '@vs/vsf-boot';
import { FormItem, Space, Tag } from '@vs/vsf-mobile';
import React, { useEffect, useRef, useState } from 'react';

import collapseDown from '@/assets/icons/collapse-down.png';
import { placeType } from '@/config';
interface valueProps {
  placeManDtoList: [];
  regionalId: string;
  placeName: string;
  placeType: string;
  id: string;
}
interface TagListInputProps {
  value?: valueProps[];
  onChange?: (value: string[]) => void;
}
interface MyFormItemProps {
  name: string;
  label?: string;
  required?: boolean;
  rules?: object[];
  onClick?: () => void;
}
const placeType1 = {};
Object.keys(placeType).map((item) => (placeType1[item] = false));
const TagListInput: React.FC<TagListInputProps> = ({ value, onChange }) => {
  const [collapse, setCollapse] = useState({ ...placeType1 });
  const [showCollapse, setShowCollapse] = useState({ ...placeType1 });

  const placeTypeFilter = (data: valueProps[], key) => {
    return data.filter((item) => item.placeType === key);
  };

  const collapseFilter = (key) => {
    if (!value) return [];
    return placeTypeFilter(value, key);
  };

  const toPlaceDetail = (id) => () => {
    vsf.navigateTo(`/add_place?id=${id}`);
  };
  useEffect(() => {
    const obj = { ...setShowCollapse };
    Object.keys(placeType1).map((key) => {
      const div = document.getElementById(key);
      if (div) {
        const height = div.clientHeight;
        obj[key] = height > 78;
      }
    });
    setShowCollapse({ ...obj });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]); // 空依赖数组确保effect只在挂载时运行
  return value?.length ? (
    <div className="tag-list">
      {Object.keys(placeType).map((key) => {
        if (!placeTypeFilter(value, key).length) return null;
        return (
          <div className="place-type-box" key={key}>
            <div className="place-type">{placeType[key]}</div>
            <div className={!collapse[key] ? 'tag-collapse-box' : ''}>
              <div id={key}>
                <Space style={{ '--gap': '16px' }} wrap>
                  {collapseFilter(key).map((item) => (
                    <Tag
                      key={item.placeName}
                      className="my-tag"
                      onClick={toPlaceDetail(item.id)}
                    >
                      {item.placeName}
                    </Tag>
                  ))}
                </Space>
              </div>
            </div>
            {showCollapse[key] ? (
              <div
                onClick={() => {
                  setCollapse({ ...collapse, [key]: !collapse[key] });
                  console.log(collapse, showCollapse, placeType1);
                }}
                className={`collapse`}
              >
                {collapse[key] ? '收起' : '展开'}
                <img
                  className={`${collapse[key] ? 'up' : 'down'}`}
                  src={collapseDown}
                  alt=""
                />
              </div>
            ) : null}
          </div>
        );
      })}
    </div>
  ) : null;
};
const PlaceTags: React.FC<MyFormItemProps> = ({
  name,
  label,
  required,
  rules,
  onClick,
}) => {
  return (
    <div className="place-tags-form_item">
      <FormItem
        // name={name}
        label={label}
        required={required}
        onClick={onClick}
      ></FormItem>
      <FormItem name={name} className="tags-input" rules={rules}>
        <TagListInput />
      </FormItem>
    </div>
  );
};
export default PlaceTags;

import vsf from '@vs/vsf-boot';
import axios from 'axios';

export async function fetchUplaod(file: any): Promise<any> {
  try {
    const polichResponse =
      await vsf.services.OssController_getOssPolicy_84844c();
    if (polichResponse.data) {
      const formData = new FormData();
      formData.append('key', `${polichResponse.data.dir}/${file.name}`);
      formData.append('policy', polichResponse.data.policy as string);
      formData.append(
        'OSSAccessKeyId',
        polichResponse.data.accessKeyId as string,
      );
      formData.append('success_action_status', '200');
      formData.append('signature', polichResponse.data.signature as string);
      formData.append('file', file);
      const uploadResponse = await axios({
        url: polichResponse.data.host,
        method: 'post',
        headers: {
          ['Content-Type']: 'multipart/form-data',
        },
        data: formData,
      });
      if (uploadResponse && polichResponse) {
        return Promise.resolve({
          uploadResponse,
          polichResponse,
        });
      }
    }
  } catch (error) {
    return Promise.reject(error);
  }
}

import './index.less';

import vsf, { definePage } from '@vs/vsf-boot';
import { Box, List, NavBar } from '@vs/vsf-mobile';
import React, { useEffect } from 'react';

import ic_jl_n from '@/assets/icons/ic_jl_n.svg';
import ic_mb_n from '@/assets/icons/ic_mb_n.svg';
import ic_qrcode from '@/assets/icons/ic_qrcode.png';
import ic_xq_n from '@/assets/icons/ic_xq_n.svg';
import ic_xz_n from '@/assets/icons/ic_xz_n.svg';
import avater_img from '@/assets/images/avater.png';
import { APP_KEY, LOGIN_TEXT_URL, LOGIN_URL } from '@/config';
const Personal: React.FC = (props: any) => {
  const { global } = props?.stores || {};
  const {
    userInfo,
    resetData,
    setIsAmdin,
    currentCampus,
    corpInfo,
    setCorpinfo,
  } = global || {};

  const handleClick = (path: string) => () => {
    vsf.navigateTo(path);
  };
  function addQueryParam(url, paramName, paramValue) {
    const urlObject = new URL(url);
    urlObject.searchParams.set(paramName, paramValue);
    return urlObject.href;
  }
  const loginOut = () => {
    resetData();
    const state = null;
    const action = 'manual';
    const sid = props.routes.query.sid || corpInfo.sid;
    const corpid = props.routes.query.corpid || corpInfo.corpid;
    const corp = props.routes.query.corp || corpInfo.corp;
    // if (sid || corpid || corp) setCorpinfo({ sid, corpid, corp });
    let redirect_uri = window.location.origin + '/personal';
    if (window.location.protocol !== 'https:')
      redirect_uri = 'https://safe-campus.byteawake.com/oauth_login';
    const urlObject = new URL(LOGIN_URL);
    APP_KEY && urlObject.searchParams.set('client_id', APP_KEY);
    sid && urlObject.searchParams.set('sid', sid);
    state && urlObject.searchParams.set('state', state);
    corp && urlObject.searchParams.set('corp', corp);
    corpid && urlObject.searchParams.set('corpid', corpid);
    action && urlObject.searchParams.set('action', action);
    redirect_uri && urlObject.searchParams.set('redirect_uri', redirect_uri);
    window.location.href = urlObject.href;
  };
  return (
    <>
      <NavBar children="个人中心" backArrow={false} />
      <Box className="personal">
        <div className="header">
          <img src={userInfo.avatar || avater_img} className="avater"></img>
          <div className="name">{userInfo.name || '未登录'}</div>
        </div>
        <div className="centent">
          <List
            style={{
              '--border-inner': 'none',
              '--prefix-padding-right': '16px',
              '--font-size': '15px',
              '--extra-max-width': '54%',
            }}
            mode="card"
          >
            <List.Item
              prefix={<img className="icon" src={ic_xq_n} />}
              extra={currentCampus?.name}
              onClick={handleClick('/campus')}
            >
              <div style={{ width: 60 }}>当前校区</div>
            </List.Item>
            <List.Item
              prefix={<img className="icon" src={ic_xz_n} />}
              onClick={handleClick('/grouping')}
            >
              分组管理
            </List.Item>
            <List.Item
              prefix={<img className="icon" src={ic_mb_n} />}
              onClick={handleClick('/region')}
            >
              区域管理
            </List.Item>
            <List.Item
              prefix={<img className="icon" src={ic_jl_n} />}
              onClick={handleClick('/label')}
            >
              标签管理
            </List.Item>
            <List.Item
              prefix={<img className="icon" src={ic_qrcode} />}
              onClick={handleClick('/visitor_code')}
            >
              二维码
            </List.Item>
          </List>
          <div className="login-out" onClick={loginOut}>
            退出登录
          </div>
        </div>
      </Box>
    </>
  );
};

export default definePage(Personal);

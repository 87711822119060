import { createServices } from '@vs/vsf-boot';
export default createServices({
  LabelController_createLabel_4cc3e6: {
    method: 'post',
    url: '/api/regional-management/create-label',
    parameterFomatter: (data?: { labels: string[] }) => data,
    responseFormatter: (_, __, data?: boolean) => data,
  },
  LabelController_getAllByLabelInfoPageQto_b03564: {
    method: 'post',
    url: '/api/regional-management/get-all-by-label-info-page-qto',
    parameterFomatter: (data?: { qto: LabelInfoPageQtoB1912C }) => data,
    responseFormatter: (
      _,
      __,
      data?: {
        count: number;
        result: LabelInfoPageVo58057A[];
        from: number;
        size: number;
        scrollId: string;
        hasMore: boolean;
      },
    ) => data,
  },
  LabelController_deleteLabel_ebd15a: {
    method: 'post',
    url: '/api/regional-management/delete-label',
    parameterFomatter: (data?: { btoParam: DeleteLabelBtoE90B92 }) => data,
    responseFormatter: (_, __, data?: boolean) => data,
  },
});

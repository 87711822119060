import './index.less';

import vsf, { definePage } from '@vs/vsf-boot';
import { Box, Input, NavBar, SearchBar, Table } from '@vs/vsf-mobile';
import dayjs from 'dayjs';
import { debounce } from 'lodash';
import React, { useCallback, useEffect, useState } from 'react';

import Apis from '@/apis';
import download from '@/assets/icons/download.png';
import ic_search from '@/assets/icons/ic_search.png';
import more from '@/assets/icons/more.png';
import MyEmpty from '@/modal/myEmpty';

const getDatesArray = (
  startDate = dayjs().subtract(7, 'day').format('YYYY-MM-DD 00:00:00'),
  endDate = dayjs().format('YYYY-MM-DD 23:59:59'),
) => {
  const dateArray = [] as any[];
  const currentDate = new Date(startDate);

  while (currentDate <= new Date(endDate)) {
    dateArray.push(dayjs(currentDate).format('M.D'));
    currentDate.setDate(currentDate.getDate() + 1);
  }
  return dateArray;
};
const getMonthArray = (startDate, endDate) => {
  const monthArray = [] as any[];
  const currentDate = new Date(startDate);
  endDate = new Date(endDate);

  while (currentDate <= new Date(endDate)) {
    monthArray.push(currentDate.getMonth() + 1 + '月');
    currentDate.setMonth(currentDate.getMonth() + 1);
  }

  return monthArray;
};
const InspectionAnalysisDetail: React.FC = (props: any) => {
  const { global } = props?.stores || {};
  const { isMobileDevice } = global || {};
  const type = props.routes.query.type;
  const startDate = props.routes.query.startDate;
  const endDate = props.routes.query.endDate;
  const timeLimit = props.routes.query.timeLimit;

  const [search, setSearch] = useState<string>();
  const [columnsData, setColumnsData] = useState<any[]>();
  const [dataSource, setDataSource] = useState<any[]>();
  const [hasMore, setHasmore] = useState(false);
  const onChange = debounce((value: string): void => {
    // console.log(value);
    setSearch(value);
  }, 300);
  const onClear = () => {
    setSearch(undefined);
  };

  const getColumns = () => {
    if (timeLimit == 1) {
      const timeArr = getMonthArray(startDate, endDate).map((month) => ({
        title: month,
        dataIndex: month,
        key: month,
        align: 'center',
        render: (text, data) => {
          const list = data.gradeStatisticsList || data.areaStatisticsList;
          console.log(month);
          list?.map((item) => console.log(dayjs(item.time).format('M月')));
          return (
            list?.find((item) => dayjs(item.time).format('M月') === month)
              ?.statisticsNum || 0
          );
        },
      }));
      setColumnsData([
        {
          title: (
            <div
              style={{ width: type === 'area' ? 80 : 40, textAlign: 'center' }}
            >
              {type === 'area' ? '场所名称' : '班级'}
            </div>
          ),
          dataIndex: 'areaName',
          key: 'areaName',
          align: 'center',
        },
        ...timeArr,
        {
          title: <div style={{ width: 40 }}>总计</div>,
          dataIndex: 'areaName',
          key: 'areaName',
          align: 'center',
          render: (t, data) => {
            const list = data.gradeStatisticsList || data.areaStatisticsList;
            return list?.reduce(
              (a, b) => a.statisticsNum || a + b.statisticsNum,
              0,
            );
          },
        },
      ]);
    } else {
      const timeArr = getDatesArray(startDate, endDate).map((day) => ({
        title: day,
        dataIndex: day,
        key: day,
        align: 'center',
        render: (text, data) => {
          const list = data.gradeStatisticsList || data.areaStatisticsList;
          return (
            list?.find((item) => dayjs(item.time).format('M.D') === day)
              ?.statisticsNum || 0
          );
        },
      }));
      setColumnsData([
        {
          title: (
            <div
              style={{ width: type === 'area' ? 80 : 40, textAlign: 'center' }}
            >
              {type === 'area' ? '场所名称' : '班级'}
            </div>
          ),
          dataIndex: 'areaName',
          key: 'areaName',
          align: 'center',
        },
        ...timeArr,
        {
          title: <div style={{ width: 40 }}>总计</div>,
          dataIndex: 'areaName',
          key: 'areaName',
          align: 'center',
          render: (t, data) => {
            const list = data.gradeStatisticsList || data.areaStatisticsList;
            return list.reduce(
              (a, b) => a.statisticsNum || a + b.statisticsNum,
              0,
            );
          },
        },
      ]);
    }
  };

  const queryAreaStatistics = async () => {
    const res =
      await vsf.services.InspectionCenterInfoController_queryGradeList_8c94dd({
        qto: {
          name: search || null,
          startTime: startDate || null,
          endTime: endDate || null,
          timeLimit: Number(timeLimit),
          campusId: vsf.stores.global.currentCampus?.campusId,
          model: type === 'area' ? 1 : 0,
        },
      });
    if (res.code === 200) {
      setDataSource(res.data || []);
      if (res.data.length > 17) {
        setHasmore(true);
      }
    } else {
      setDataSource([]);
    }
  };
  const exportExcel = async () => {
    if (isMobileDevice) {
      return vsf.showToast('手机暂不支持下载');
    }
    const data = {
      name: search || null,
      startTime: startDate || null,
      endTime: endDate || null,
      timeLimit: Number(timeLimit),
      campusId: vsf.stores.global.currentCampus?.campusId,
      model: type === 'area' ? '1' : '0',
    };

    fetch('/api/export/exportPoiExcel', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    })
      .then((response) => response.blob()) // 转换为blob
      .then((blob) => {
        const filename = `${dayjs(startDate).format(
          timeLimit === '0' ? 'YYYY.MM.DD' : 'YYYY.MM',
        )}-${dayjs(endDate).format(
          timeLimit === '0' ? 'YYYY.MM.DD' : 'YYYY.MM',
        )}${type === 'area' ? '区域报表.xls' : '年级报表.xls'}`;
        const blobUrl = window.URL.createObjectURL(blob); // 创建blob的URL
        const a = document.createElement('a'); // 创建一个<a>元素
        a.href = blobUrl;
        a.download = filename; // 设置下载文件的名称
        document.body.appendChild(a); // 将<a>元素添加到页面中
        a.click(); // 模拟点击以触发下载
        a.remove(); // 下载后移除元素
        window.URL.revokeObjectURL(blobUrl); // 清理blob URL
      })
      .catch((error) => console.error('Error:', error));
  };
  useEffect(() => {
    getColumns();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    queryAreaStatistics();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search]);
  return (
    <>
      <NavBar
        children="查看报表"
        backArrow={true}
        onBack={() => vsf.goBack()}
      />
      <Box className="inspection-analysis-detail">
        <div className="header">
          <div className="search">
            <SearchBar
              style={{
                '--placeholder-color': '#8FB4FA',
                '--background': 'transparent',
              }}
              placeholder={''}
              onChange={onChange}
              onClear={onClear}
            />
            {!search ? (
              <div className="placeholder">
                <img src={ic_search} alt="" />
                {type === 'area' ? '搜索场所' : '搜索班级'}
              </div>
            ) : null}
          </div>
          <div className="download" onClick={exportExcel}>
            <img src={download} alt="" />
            下载报表
          </div>
        </div>
        {!dataSource || dataSource?.length ? (
          <Table
            rowKey="areaName"
            pagination={false}
            dataSource={hasMore ? dataSource?.slice(0, 17) : dataSource}
            columns={columnsData}
            scroll={{ x: '100%' }}
          />
        ) : (
          <MyEmpty text={search ? '没有搜索到结果' : '暂无数据'} />
        )}
        {hasMore ? (
          <div className="more">
            <div className="more-btn" onClick={() => setHasmore(false)}>
              <div className="more-btn-l"></div>
              <div className="more-btn-c">
                查看更多
                <img src={more} alt="" />
              </div>
              <div className="more-btn-r"></div>
            </div>
          </div>
        ) : null}
      </Box>
    </>
  );
};

export default definePage(InspectionAnalysisDetail);

import './index.less';

import vsf, { definePage } from '@vs/vsf-boot';
import {
  Box,
  CalendarPicker,
  Input,
  NavBar,
  SpinLoading,
} from '@vs/vsf-mobile';
import dayjs from 'dayjs';
import * as echarts from 'echarts';
import React, { useEffect, useState } from 'react';

import down from '@/assets/icons/timedown.png';
import MyCalenderPicker from '@/modal/myCalenderPicker';
import MyEmpty from '@/modal/myEmpty';

const colors = [
  '#4BEBB7',
  '#F9D14C',
  '#FF9F5B',
  '#1D7BFF',
  '#5EEBFF',
  '#FF86A7',
  '#FF81A4',
];

function getGradeName(grade) {
  switch (grade) {
    case '7':
      return '七年级';
    case '8':
      return '八年级';
    case '9':
      return '九年级';
    default:
      return '未知年级';
  }
}

const InspectionAnalysis: React.FC = (props: any) => {
  const { global } = props?.stores || {};
  const { inspectionAnalysisData, setInspectionAnalysisData } = global || {};
  const [chart, setChart] = useState({});
  const [empty1, setEmpty1] = useState<0 | 1 | 2>(0); // 0加载,1无数据,2有数据
  const [empty2, setEmpty2] = useState<0 | 1 | 2>(0);
  const [empty3, setEmpty3] = useState<0 | 1 | 2>(0);

  const getLineSeries = (data) => {
    return data.map((item, index) => {
      return {
        id: item.id,
        name: item.name,
        axisTick: {
          alignWithLabel: true,
        },
        type: 'line',
        smooth: true, // 曲线平滑
        itemStyle: {
          normal: {
            color: colors[index], // 曲线颜色
            lineStyle: {
              width: 1, // 线宽
            },
          },
        },
        symbol: 'circle', // 折线点设置为实心圆
        symbolSize: 6, // 折线点的大小
        data: item.value,
        // emphasis: {
        //   itemStyle: {
        //     borderColor: 'rgba(0,0,0,0.5)',
        //     borderWidth: 10,
        //   },
        // },
      };
    });
  };

  // 曲线图
  const handleLineChart = (id, chartData, xAxis) => {
    let myChart;
    const dom = document.getElementById(id);
    if (chart[id]) myChart = chart[id];
    else {
      dom && (myChart = echarts.init(dom));
    }
    const option = {
      colors: colors,
      tooltip: {
        trigger: 'item',
        valueFormatter: (value) => {
          return value;
        },
      },
      legend: {
        type: 'plain',
        show: true,
        left: 'center',
        top: 2,
        itemWidth: 12,
        itemHeight: 12,
        icon: 'circle',
        selectedMode: true,
        textStyle: {
          color: '#A3A3A3',
        },
      },
      grid: {
        left: 0,
        containLabel: true,
        top: 60,
        right: 0,
        bottom: 10,
      },
      xAxis: {
        type: 'category',
        data: xAxis,
        axisLine: {
          show: false,
        },
        axisTick: {
          show: false,
        },
      },
      yAxis: {
        type: 'value',
        minInterval: 1,
      },
      series: getLineSeries(chartData),
    };
    dom && option && myChart.setOption(option);
    !chart[id] && setChart({ ...chart, [id]: myChart });
    // 添加点击事件监听
    myChart.on('click', function (params) {
      // 控制台打印数据的名称
      console.log(params);
    });
  };
  // 饼图
  const handlePieChart = (id, chartData) => {
    let myChart;
    const dom = document.getElementById(id);
    if (chart[id]) myChart = chart[id];
    else {
      dom && (myChart = echarts.init(dom));
    }
    const option = {
      colors: colors,
      // tooltip: {
      //   trigger: 'item',
      //   valueFormatter: (value) => {
      //     return value;
      //   },
      // },

      series: [
        {
          type: 'pie',
          radius: [0, 85],
          roseType: 'area',
          itemStyle: {
            borderRadius: 0,
          },
          data: chartData,
          label: {
            formatter: '{a|{b}} \n {b|{c}}{x| 条}',
            rich: {
              a: {
                fontSize: 12,
                fontWeight: 400,
              },
              b: {
                fontSize: 20,
                color: '#48A2FF',
              },
              x: {
                fontSize: 12,
                color: '#48A2FF',
                lineHeight: 35,
              },
            },
            overflow: 'break',
          },
        },
      ],
    };
    dom && option && myChart.setOption(option);
    !chart[id] && setChart({ ...chart, [id]: myChart });
  };
  const areaDateChange = (date, timeLimit) => {
    setInspectionAnalysisData({
      ...inspectionAnalysisData,
      date2: date,
      timeLimit2: timeLimit,
      init: false,
    });
    console.log(date);
  };
  const gradeDateChange = (date, timeLimit) => {
    setInspectionAnalysisData({
      ...inspectionAnalysisData,
      date1: date,
      timeLimit1: timeLimit,
      init: false,
    });
    console.log(date);
  };
  const labelDateChange = (date) => {
    setInspectionAnalysisData({
      ...inspectionAnalysisData,
      date3: date,
      init: false,
    });
    console.log(date);
  };

  const toDeatil = (type) => {
    let startDate = dayjs().subtract(6, 'day').format('YYYY-MM-DD 00:00:00');
    let endDate = dayjs().format('YYYY-MM-DD 23:59:59');
    let timeLimit = 0;
    if (type === 'grade' && inspectionAnalysisData.date1) {
      startDate = inspectionAnalysisData.date1[0];
      endDate = inspectionAnalysisData.date1[1];
      timeLimit = inspectionAnalysisData.timeLimit1;
    }
    if (type === 'area' && inspectionAnalysisData.date2) {
      startDate = inspectionAnalysisData.date2[0];
      endDate = inspectionAnalysisData.date2[1];
      timeLimit = inspectionAnalysisData.timeLimit2;
    }
    vsf.navigateTo(
      `/InspectionAnalysisDetail?type=${type}&startDate=${startDate}&endDate=${endDate}&timeLimit=${timeLimit}`,
    );
  };

  const queryGradeStatistics = async () => {
    const res =
      await vsf.services.InspectionCenterInfoController_queryGradeStatistics_38e03b(
        {
          qto: {
            name: null,
            startTime: inspectionAnalysisData.date1?.[0] || null,
            endTime: inspectionAnalysisData.date1?.[1] || null,
            timeLimit: inspectionAnalysisData.timeLimit1,
            campusId: vsf.stores.global.currentCampus?.campusId,
          },
        },
      );
    setEmpty1(1);
    if (res.code === 200) {
      // const reloDate = res.data?.filter((item) => item.statisticsInfo.length);
      if (!res.data?.length) return setEmpty1(1);
      setEmpty1(2);
      const xAxis =
        inspectionAnalysisData.timeLimit1 === 1
          ? getMonthArray(
              inspectionAnalysisData.date1?.[0],
              inspectionAnalysisData.date1?.[1],
            )
          : getDatesArray(
              inspectionAnalysisData.date1?.[0],
              inspectionAnalysisData.date1?.[1],
            );

      const data = res.data?.map((item) => ({
        name: getGradeName(item.areaName),
        value: xAxis.map((x) => {
          return (
            item.statisticsInfo?.find(
              (info) =>
                dayjs(info.time).format(
                  inspectionAnalysisData.timeLimit1 === 0 ? 'M.D' : 'M月',
                ) === x,
            )?.num || 0
          );
        }),
      }));

      console.log('xAxis', xAxis);
      handleLineChart('chart1', data, xAxis);
    }
  };
  const queryAreaStatistics = async () => {
    const res =
      await vsf.services.InspectionCenterInfoController_queryAreaStatistics_b8534b(
        {
          areaStatisticsBvo: {
            name: null,
            startTime: inspectionAnalysisData.date2?.[0] || null,
            endTime: inspectionAnalysisData.date2?.[1] || null,
            timeLimit: inspectionAnalysisData.timeLimit2,
            campusId: vsf.stores.global.currentCampus?.campusId,
          },
        },
      );
    setEmpty2(1);
    if (res.code === 200) {
      // const reloDate = res.data?.filter((item) => item.statisticsInfo.length);
      if (!res.data?.length) return setEmpty2(1);
      setEmpty2(2);
      const xAxis =
        inspectionAnalysisData.timeLimit2 === 1
          ? getMonthArray(
              inspectionAnalysisData.date2?.[0],
              inspectionAnalysisData.date2?.[1],
            )
          : getDatesArray(
              inspectionAnalysisData.date2?.[0],
              inspectionAnalysisData.date2?.[1],
            );
      const data = res.data?.map((item) => ({
        name: item.areaName,
        value: xAxis.map((x) => {
          return (
            item.statisticsInfo?.find(
              (info) =>
                dayjs(info.time).format(
                  inspectionAnalysisData.timeLimit2 === 0 ? 'M.D' : 'M月',
                ) === x,
            )?.num || 0
          );
        }),
      }));

      console.log(xAxis);
      handleLineChart('chart2', data, xAxis);
    }
  };
  const queryLabelStatistics = async () => {
    const res =
      await vsf.services.InspectionCenterInfoController_getLabelStatistics_3a7788(
        {
          qto: {
            // nameIs: null,
            createdAtRangeIn: {
              begin: inspectionAnalysisData.date3?.[0] || null,
              end: inspectionAnalysisData.date3?.[1] || null,
              // beginInclude: true,
              // endInclude: true,
            },
            campusIdIs: vsf.stores.global.currentCampus?.campusId,
          },
        },
      );
    setEmpty3(1);
    if (res.code === 200) {
      if (!res.data?.length) return setEmpty3(1);
      setEmpty3(2);
      const data = res.data?.map((item) => ({
        name: item.name,
        value: item.num,
      }));
      handlePieChart('chart3', data);
    }
  };

  const getDatesArray = (
    startDate = dayjs().subtract(6, 'day').format('YYYY-MM-DD 00:00:00'),
    endDate = dayjs().format('YYYY-MM-DD 23:59:59'),
  ) => {
    const dateArray = [] as any[];
    const currentDate = new Date(startDate);

    while (currentDate <= new Date(endDate)) {
      dateArray.push(dayjs(currentDate).format('M.D'));
      currentDate.setDate(currentDate.getDate() + 1);
    }
    return dateArray;
  };
  const getMonthArray = (startDate, endDate) => {
    const monthArray = [] as any[];
    const currentDate = new Date(startDate);
    endDate = new Date(endDate);

    while (currentDate <= new Date(endDate)) {
      monthArray.push(currentDate.getMonth() + 1 + '月');
      currentDate.setMonth(currentDate.getMonth() + 1);
    }

    return monthArray;
  };
  useEffect(() => {
    queryGradeStatistics();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inspectionAnalysisData.date1, inspectionAnalysisData.timeLimit1]);
  useEffect(() => {
    queryAreaStatistics();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inspectionAnalysisData.date2, inspectionAnalysisData.timeLimit2]);
  useEffect(() => {
    queryLabelStatistics();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inspectionAnalysisData.date3]);

  return (
    <>
      <NavBar
        children="数据汇总"
        backArrow={true}
        onBack={() => vsf.goBack()}
      />
      <Box className="inspection-analysis">
        <div className="card">
          <div className="header">
            <div className="label">年级对比</div>
            <div className="detail" onClick={() => toDeatil('grade')}>
              查看详情
            </div>
          </div>
          <MyCalenderPicker
            date={inspectionAnalysisData.date1}
            timeLimit={inspectionAnalysisData.timeLimit1}
            onOk={gradeDateChange}
          />
          {empty1 === 0 ? (
            <SpinLoading style={{ margin: '48px auto' }} />
          ) : null}
          <div
            style={empty1 !== 1 ? undefined : { display: 'none' }}
            className="duration-chart"
            id="chart1"
          ></div>
          {empty1 === 1 ? (
            <MyEmpty
              style={{ maxHeight: '200px', width: '35%', overflow: 'hidden' }}
              text="暂无数据"
            />
          ) : null}
        </div>
        <div className="card">
          <div className="header">
            <div className="label">区域对比</div>
            <div className="detail" onClick={() => toDeatil('area')}>
              查看详情
            </div>
          </div>
          <MyCalenderPicker
            date={inspectionAnalysisData.date2}
            timeLimit={inspectionAnalysisData.timeLimit2}
            onOk={areaDateChange}
          />
          {empty2 === 0 ? (
            <SpinLoading style={{ margin: '48px auto' }} />
          ) : null}
          <div
            style={empty2 !== 1 ? undefined : { display: 'none' }}
            className="duration-chart"
            id="chart2"
          ></div>
          {empty2 === 1 ? (
            <MyEmpty
              style={{ maxHeight: '200px', width: '35%', overflow: 'hidden' }}
              text="暂无数据"
            />
          ) : null}
        </div>
        <div className="card">
          <div className="header">
            <div className="label">标签画像</div>
            {/* <div className="detail" onClick={() => toDeatil('label')}>
              查看详情
            </div> */}
          </div>
          <MyCalenderPicker
            date={inspectionAnalysisData.date3}
            onOk={labelDateChange}
            type="day"
          />
          {empty3 === 0 ? (
            <SpinLoading style={{ margin: '48px auto' }} />
          ) : null}
          <div
            style={empty3 !== 1 ? undefined : { display: 'none' }}
            className="duration-chart"
            id="chart3"
          ></div>
          {empty3 === 1 ? (
            <MyEmpty
              style={{ maxHeight: '200px', width: '35%', overflow: 'hidden' }}
              text="暂无数据"
            />
          ) : null}
        </div>
      </Box>
    </>
  );
};

export default definePage(InspectionAnalysis);

import './index.less';

import vsf from '@vs/vsf-boot';
import React from 'react';

import deltag from '@/assets/icons/deltag.png';

interface DelTagProps {
  title?: string;
  onRemove?: () => void;
}

const Deltag: React.FC<DelTagProps> = ({ title, onRemove }) => {
  return (
    <div className="my-del-tag">
      <span>{title}</span>
      <div className="del-box" onClick={onRemove}>
        <img src={deltag} alt="" />
      </div>
    </div>
  );
};
export default Deltag;

import './index.less';

import vsf from '@vs/vsf-boot';
import React from 'react';
interface FixedBtnProps {
  onConfirm: () => void;
  onReset: () => void;
  restText?: string;
  confirmText?: string;
}
const FixedBtn: React.FC<FixedBtnProps> = ({
  onConfirm,
  onReset,
  restText,
  confirmText,
}) => {
  return (
    <div className="form-bottom-btn-box">
      <div className="btn reset" onClick={onReset}>
        <span>{restText || '重置'}</span>
      </div>
      <div className="btn confirm" onClick={onConfirm}>
        <span>{confirmText || '确认'}</span>
      </div>
    </div>
  );
};

export default FixedBtn;

import './index.less';

import vsf, { definePage } from '@vs/vsf-boot';
import {
  Box,
  InfiniteScroll,
  NavBar,
  SearchBar,
  SideBar,
  Skeleton,
  SpinLoading,
} from '@vs/vsf-mobile';
import React, { useEffect, useRef, useState } from 'react';

import userIcon from '@/assets/icons/userIcon.png';
import MyEmpty from '@/modal/myEmpty';

const InspectionRecord = (props) => {
  const Id = props?.routes?.query?.Id ?? undefined;
  const [hasMore, setHasMore] = useState(false);
  const [count, setCount] = useState(-1);
  const [from, setFrom] = useState(1);
  const [reginList, setReginList] = useState([]);
  const [recordList, setRecordList] = useState([]);
  const [areaId, setAreaId] = useState();
  const [placeName, setPlaceName] = useState();
  const [loading, setLoading] = useState(false);
  const accontInfo = vsf.stores.global;

  useEffect(() => {
    handleGetReginData();
  }, []);

  useEffect(() => {
    if (areaId) {
      handleGetRecordData();
    }
  }, [areaId, placeName]);

  const handleGetReginData = async () => {
    setLoading(true);
    const res =
      await vsf.services?.RegionalManagementController_getPagedByRegionalPageQto_b8dd16(
        {
          qto: {
            campusIdIs: vsf.stores.global.currentCampus?.campusId,
            from: 0,
            size: 10000,
          },
        },
      );
    if (res && res?.code === 200) {
      const dataList = res?.data?.result?.map((item) => {
        return {
          title: item?.regionalName,
          key: item?.id?.toString(),
        };
      });
      setReginList(dataList);
      setAreaId(Id ?? res?.data?.result?.[0]?.id);
      setLoading(false);
    }
  };

  const handleGetRecordData = async () => {
    const res =
      await vsf.services.InspectionCenterInfoController_getPlaceGroup_7b120c({
        qto: {
          areaIdIs: areaId,
          campusIdIs: vsf.stores.global.currentCampus?.campusId,
          placeLike: placeName,
        },
      });
    if (res.code === 200 || res.data) {
      setRecordList(res.data);
      setCount(res.data?.length);
      // setHasMore(res.data?.result?.length === 1);
      // setFrom(from + 1);
    }
  };

  return (
    <>
      <NavBar children="平安校园" backArrow={false} />
      <Box>
        <div className="InspectionRecordContainer">
          <div className="InspectionRecordContainer-top">
            <div className="InspectionRecordContainer-top__title">
              {accontInfo?.currentCampus?.appInfoName ?? ''}
            </div>
            <div className="InspectionRecordContainer-top__name">
              <div className="InspectionRecordContainer-top__name__icon">
                <img src={userIcon} alt="" />
              </div>
              <div className="InspectionRecordContainer-top__name__user">
                {accontInfo?.userInfo?.name ?? ''}
              </div>
            </div>
          </div>

          <div className="InspectionRecordContainer-center">
            <div className="InspectionRecordContainer-center__top">
              <SearchBar
                placeholder="搜场所"
                style={{
                  '--placeholder-color': '#D4D4D4',
                }}
                clearable
                onSearch={(v) => {
                  setPlaceName(v);
                }}
                onChange={(v) => {
                  setPlaceName(v);
                }}
                onClear={(v) => {
                  setPlaceName(undefined);
                }}
              />
              <div
                className="InspectionRecordContainer-center__top__btn"
                onClick={() => {
                  areaId
                    ? vsf?.navigateTo(`./InspectionRecordForm?areaId=${areaId}`)
                    : vsf?.navigateTo('./InspectionRecordForm');
                }}
              >
                新增巡检记录
              </div>
            </div>
            <div className="InspectionRecordContainer-center__con">
              <div className="InspectionRecordContainer-center__con__left">
                {reginList?.length > 0 ? (
                  <>
                    <SideBar
                      onChange={(key) => {
                        setAreaId(key);
                      }}
                      activeKey={areaId?.toString()}
                    >
                      {reginList?.map((item, key) => (
                        <SideBar.Item key={item?.key} title={item.title} />
                      ))}
                    </SideBar>
                  </>
                ) : (
                  <>
                    <div className="loadingText">暂无数据</div>
                    {loading && (
                      <>
                        <SpinLoading className="loadingBox" />
                      </>
                    )}
                  </>
                )}
              </div>
              <div className="InspectionRecordContainer-center__con__right">
                {recordList?.map((item, index) => {
                  return (
                    <>
                      <div
                        className="InspectionRecordContainer-center__con__right__item"
                        key={index}
                        onClick={() => {
                          vsf?.navigateTo(
                            `./InspectionRecordDetail?placeId=${item?.placeId}`,
                          );
                        }}
                      >
                        <div className="InspectionRecordContainer-center__con__right__item__class">
                          <div> {item?.name}</div>
                          {item?.placeType === 'TEAM_AREA' && (
                            <>
                              <div>班级</div>
                            </>
                          )}
                        </div>
                        <div className="InspectionRecordContainer-center__con__right__item__record">
                          <div
                            style={{
                              color: '#434E5E',
                            }}
                          >
                            {item?.num}
                          </div>
                          <div
                            style={{
                              fontSize: '0.75rem',
                              fontWeight: '400',
                            }}
                          >
                            记录(条)
                          </div>
                        </div>
                        <div className="InspectionRecordContainer-center__con__right__item__btn">
                          查看记录
                        </div>
                      </div>
                    </>
                  );
                })}

                {reginList?.length === 0 || count === 0 ? (
                  <MyEmpty text="当前无巡检记录" />
                ) : (
                  <>
                    <InfiniteScroll
                      loadMore={handleGetRecordData}
                      hasMore={hasMore}
                    />
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </Box>
    </>
  );
};

export default definePage(InspectionRecord);

import QRCode from 'qrcode.react';
import React from 'react';

const DownloadQRCode = ({ value, size, fileName }) => {
  const downloadQRCode = () => {
    const canvas = document.getElementById('qrCodeEl') as any;
    if (!canvas) return;
    const pngUrl = canvas
      .toDataURL('image/png')
      .replace('image/png', 'image/octet-stream');
    const downloadLink = document.createElement('a');
    downloadLink.href = pngUrl;
    downloadLink.download = `${fileName}.png`;
    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);
  };

  return (
    <>
      <QRCode id="qrCodeEl" value={value} size={size} />
      <button onClick={downloadQRCode}>下载{fileName}</button>
    </>
  );
};

export default DownloadQRCode;

import vsf, { definePage } from '@vs/vsf-boot';
import { Box, List, NavBar } from '@vs/vsf-mobile';
import React, { useEffect } from 'react';
const Oauth = (props) => {
  const login = () => {
    console.log(props.routes.query);
    const queryStr = Object.keys(props.routes.query).map(key => `${key}=${props.routes.query[key]}`).join('&');
    window.location.replace(`http://localhost:3000/personal?${queryStr}`);
  };

  useEffect(() => {
    login()
  }, []);

  return (
    <>
      <NavBar children="登录中转页" backArrow={false} />
    </>
  );
};

export default definePage(Oauth);

import './index.less';
import vsf, { definePage } from '@vs/vsf-boot';
import { Box, List, NavBar } from '@vs/vsf-mobile';
import React, { useEffect } from 'react';
import { APP_KEY } from '@/config';
import pic_book from '@/assets/images/pic_book.png'
const Login = (props) => {
  const { global } = props?.stores || {};
  const { resetData, corpInfo, setCorpinfo } = global || {};

  function addQueryParam(url, paramName, paramValue) {
    const urlObject = new URL(url);
    urlObject.searchParams.set(paramName, paramValue);
    return urlObject.href;
  }

  const login = () => {
    const url = 'https://sso.schbrain.com/sso/oauth2/authorize?response_type=code';
    const textUrl =
      'https://sso-dev.schbrain.com/sso/oauth2/authorize?response_type=code';
    const state = null;
    const action = 'manual';
    const sid = props.routes.query.sid || corpInfo.sid;
    const corpid = props.routes.query.corpid || corpInfo.corpid;
    const corp = props.routes.query.corp || corpInfo.corp;
    if (sid || corpid || corp) setCorpinfo({ sid, corpid, corp });
    let redirect_uri = window.location.origin + '/personal';
    if (window.location.protocol !== 'https:')
      redirect_uri = 'https://safe-campus.byteawake.com/oauth_login';
    const urlObject = new URL(textUrl);
    APP_KEY && urlObject.searchParams.set('client_id', APP_KEY);
    corpid && urlObject.searchParams.set('corpid', corpid);
    sid && urlObject.searchParams.set('sid', sid);
    state && urlObject.searchParams.set('state', state);
    corp && urlObject.searchParams.set('corp', corp);
    action && urlObject.searchParams.set('action', action);
    redirect_uri && urlObject.searchParams.set('redirect_uri', redirect_uri);
    window.location.href = urlObject.href;
  };
  useEffect(() => {
    resetData();
  }, []);

  return (
    <>
      <NavBar children="学校大脑" backArrow={true}
        onBack={() => vsf.goBack()} />
      <Box className="login">
        <img className='book-img' src={pic_book} alt="" />
        <div className='login-btn' onClick={login}>登录</div>
      </Box>
    </>
  );
};

export default definePage(Login);

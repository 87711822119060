import './index.less';

import vsf, { definePage } from '@vs/vsf-boot';
import { Box, InfiniteScroll, List, NavBar } from '@vs/vsf-mobile';
import dayjs from 'dayjs';
import React, { useEffect, useState } from 'react';

import btn_up from '@/assets/images/btn_up.png';
import empty from '@/assets/images/empty.png';
import MyEmpty from '@/modal/myEmpty';

interface VistorItem {
  id: number;
  createdAt: string;
  reason: string;
  interviewee: string;
  visitor: string;
}
const Vistor: React.FC = (props: any) => {
  const { global } = props?.stores || {};
  const { userInfo, currentCampus } = global || {};
  const [vistorList, setVistorList] = useState<VistorItem[]>([]);
  const [hasMore, setHasMore] = useState(true);
  const [from, setFrom] = useState(0);
  const [count, setCount] = useState(-1);

  const reqVistors = async () => {
    const res =
      (await vsf.services.VisitorController_getAllByVisitorCenterQto_4cf845({
        qto: {
          campusIdIs: currentCampus?.campusId,
          size: 10,
          from,
        },
      })) as any;
    if (res.code === 200 || res.data) {
      setVistorList([...vistorList, ...res.data.result]);
      setCount(res.data.count);
      setHasMore(res.data.result.length === 10);
      setFrom(from + 1);
    } else {
      setHasMore(false);
    }
  };

  const scrollTop = () => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth',
    });
  };

  const showScrollBtn = () => {
    // 获取按钮元素
    const myButton = document.getElementById('myButton');
    if (myButton) {
      // 隐藏按钮
      myButton.style.display = 'none';
      // 设置当页面滚动超过100px时显示按钮
      const toggleButtonVisibility = () => {
        if (window.scrollY > 200) {
          myButton.style.display = 'block';
        } else {
          myButton.style.display = 'none';
        }
      };
      // 监听页面滚动事件
      window.addEventListener('scroll', toggleButtonVisibility);
    }
  };

  const toDetail = (id) => () => {
    vsf.navigateTo(`/visitor_create?id=${id}`);
  };
  useEffect(() => {
    showScrollBtn();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <>
      <NavBar
        children="访客记录"
        backArrow={true}
        onBack={() => vsf.goBack()}
      />
      <Box className="vistor">
        <div className="header-bg">
          <div className="name">{currentCampus?.appInfoName}</div>
        </div>
        <div className="list">
          {vistorList.map((item, index) => (
            <div key={item.id}>
              <div className="list-item" onClick={toDetail(item.id)}>
                <div className="content">
                  <div className="head">
                    <div className="info">
                      <div className="label">到访人：</div>
                      <div className="value">{item.visitor}</div>
                    </div>
                    <div className="info">
                      <div className="label">受访人：</div>
                      <div className="value">{item.interviewee}</div>
                    </div>
                  </div>
                  <div className="info">
                    <div className="label">到访理由：</div>
                    <div className="value">{item.reason}</div>
                  </div>
                </div>
                <div className="footer">
                  到访时间：{dayjs(item.createdAt).format('M月D日 HH:mm')}
                </div>
              </div>
            </div>
          ))}
        </div>
        {count === 0 ? (
          <MyEmpty text="当前无访客记录" />
        ) : (
          <InfiniteScroll loadMore={reqVistors} hasMore={hasMore} />
        )}
        <div id="myButton" className="back-up" onClick={scrollTop}>
          <img src={btn_up} alt="" />
        </div>
      </Box>
    </>
  );
};

export default definePage(Vistor);

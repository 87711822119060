import './index.less';

import vsf, { definePage } from '@vs/vsf-boot';
import {
  Box,
  Button,
  Form,
  InfiniteScroll,
  Input,
  Mask,
  Modal,
  NavBar,
} from '@vs/vsf-mobile';
import React, { useEffect, useRef, useState } from 'react';

import addSvg from '@/assets/icons/add.svg';
import add1Svg from '@/assets/icons/add1.png';
import del from '@/assets/icons/del.svg';
import importSvg from '@/assets/icons/import.svg';
import ConfirmMask from '@/modal/confirmMask';
import FixedBtn from '@/modal/fixedBtn';
import MyEmpty from '@/modal/myEmpty';
import { fetchUplaod } from '@/utils/upload';
import { extractTables } from '@/utils/xlsx';
interface LabelItem {
  id: number;
  createdAt: string;
  labelName: string;
}
interface FormValues {
  [key: string]: string;
}

const Label: React.FC = (props: any) => {
  const { global } = props?.stores || {};
  const { userInfo, isMobileDevice, currentCampus } = global || {};
  const [visible, setVisible] = useState(false);
  const [maskVisible, setMaskVisible] = useState(0);
  const [labels, setLabels] = useState<LabelItem[]>([]);
  const [addlabelNum, setAddLabelNum] = useState(['1']);
  const [loading, setLoading] = useState(false);
  const [hasMore, setHasMore] = useState(true);
  const [from, setFrom] = useState(0);
  const [count, setCount] = useState(-1);
  const openModal = () => {
    setVisible(true);
  };
  const closeModal = () => {
    setVisible(false);
    setAddLabelNum(['1']);
  };
  const addlabel = () => {
    setAddLabelNum([...addlabelNum, '1']);
  };
  const onFinish = (values: FormValues) => {
    addLabels(
      Object.keys(values)
        .map((key) => values[key])
        .filter((value) => value),
    );
  };

  const reqLabels = async () => {
    const res =
      (await vsf.services.RegionalManagementController_getPagedByLabelInfoPageQto_25249d(
        {
          qto: {
            campusIdIs: currentCampus?.campusId,
            from,
            size: 20,
          },
        },
      )) as any;
    if (res.code === 200 && res.data) {
      setLabels([...labels, ...res.data.result]);
      setCount(res.data.count);
      setHasMore(res.data.result.length === 20);
      setFrom(from + 1);
    } else {
      setHasMore(false);
    }
  };

  const reload = () => {
    setFrom(0);
    setCount(-1);
    setLabels([]);
    setHasMore(true);
  };
  const addLabels = async (values) => {
    if (!values.length) return closeModal();
    setLoading(true);
    const res =
      await vsf.services.RegionalManagementController_createBatchLabelInfo_f69ce4(
        {
          btoParam: values.map((value) => ({
            labelName: value,
            campusId: currentCampus?.campusId,
          })),
        },
      );
    setLoading(false);
    if (res.code === 200) {
      vsf.showToast('提交成功', 'success');
      closeModal();
      reload();
    }
  };
  const importLabels = async (values) => {
    const res =
      await vsf.services.RegionalManagementController_createBatchLabelInfo_f69ce4(
        {
          btoParam: values.map((value) => ({
            labelName: value,
            campusId: currentCampus?.campusId,
          })),
        },
      );
    if (res.code === 200) {
      vsf.showToast('导入成功', 'success');
      reload();
    }
  };
  const removeLabels = async () => {
    const res =
      await vsf.services.RegionalManagementController_deleteLabelInfo_c7c53c({
        btoParam: {
          id: maskVisible,
        },
      });
    if (res.code === 200) {
      vsf.showToast('删除成功', 'success');
      setMaskVisible(0);
      reload();
    }
  };

  const fileInputRef = useRef<HTMLInputElement>(null);

  const handleUploadClick = () => {
    // 触发input的点击事件
    fileInputRef.current!.click();
  };

  const customRequest = async (e) => {
    try {
      const response = await extractTables(
        e.file,
        {
          label: '*标签名',
        },
        ['label'],
      );
      console.log(response, 'response====');
      if (response) {
        if (response?.[0]?.data && response?.[0]?.data?.length > 0) {
          console.log(response?.[0]?.data.map((item) => item.label));
          importLabels(response?.[0]?.data.map((item) => item.label));
          setTimeout(() => {
            fetchUplaod(e.file);
          }, 1);
        } else {
          vsf.showToast('表格中数据不完整');
        }
      }
    } catch (error) {
      console.error(error);
      vsf.showToast('解析失败，请检查文档格式');
    }
  };
  const handleFileChange = async (event) => {
    console.log(event, 'file changed');
    const file = event.target.files[0];
    if (file) {
      // 检查文件类型
      if (
        file.type ===
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
      ) {
        // 自定义上传逻辑
        await customRequest({ file });
        event.target.value = '';
      } else {
        // 错误处理
        console.error('请上传.xls,.xlsx格式的文件');
      }
    }
  };
  return (
    <>
      <NavBar
        children="标签管理"
        backArrow={true}
        onBack={() => vsf.goBack()}
      />
      <Box className="page-label">
        {labels.length > 0 || !isMobileDevice ? (
          <div className="list">
            {labels.map((item) => {
              return (
                <div key={item.id} className="list-item">
                  <div className="label-title">{item.labelName}</div>
                  <div
                    className="label-del"
                    onClick={() => setMaskVisible(item.id)}
                  >
                    <img src={del} alt="" />
                  </div>
                </div>
              );
            })}
            {!isMobileDevice ? (
              <div className="import-box">
                <div className="import" onClick={handleUploadClick}>
                  <img src={importSvg}></img>导入标签
                  <input
                    type="file"
                    ref={fileInputRef}
                    style={{ display: 'none' }}
                    onChange={handleFileChange}
                    accept=".xls,.xlsx"
                  />
                </div>
                <a href="/导入标签模板.xlsx">点击下载标签模板</a>
              </div>
            ) : null}
          </div>
        ) : null}
        {count === 0 ? (
          <MyEmpty text="当前还未设置巡检标签" />
        ) : (
          <InfiniteScroll loadMore={reqLabels} hasMore={hasMore} />
        )}
        <FixedBtn
          onClick={openModal}
          icon={<img src={addSvg}></img>}
          text="新增标签"
        />
        <Modal
          visible={visible}
          bodyClassName="labelModal"
          content={
            <Form
              layout="horizontal"
              footer={
                <div className="footer">
                  <Button
                    shape="rounded"
                    className="close"
                    onClick={closeModal}
                    block
                    size="middle"
                  >
                    取消
                  </Button>
                  <Button
                    shape="rounded"
                    className="submit"
                    block
                    type="submit"
                    color="primary"
                    size="middle"
                    loading={loading}
                  >
                    确定
                  </Button>
                </div>
              }
              onFinish={onFinish}
            >
              {addlabelNum.map((item, index) => {
                return (
                  <Form.Item name={[index]} key={index}>
                    <Input />
                  </Form.Item>
                );
              })}
              {addlabelNum.length < 5 && (
                <div className="add">
                  <div className="add-btn" onClick={addlabel}>
                    <img src={add1Svg} alt="" />
                    <span>新增一项</span>
                  </div>
                </div>
              )}
            </Form>
          }
          closeOnAction
          onClose={() => {
            setVisible(false);
          }}
        />
        <ConfirmMask
          visible={!!maskVisible}
          text="确定删除该标签吗？"
          onConfirm={removeLabels}
          onMaskClick={() => setMaskVisible(0)}
        />
      </Box>
    </>
  );
};

export default definePage(Label);

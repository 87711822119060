import './index.less';

import vsf from '@vs/vsf-boot';
import { Radio, Space } from '@vs/vsf-mobile';
import React, { useEffect, useState } from 'react';

import MyPopup from '@/modal/myPopup';

interface FormAreaProps {
  visible?: boolean;
  onClose?: () => void;
  onOk?: (params: LabelItem | undefined) => void;
  defaultValue: string;
  getInitArea?: (params: LabelItem | undefined) => void;
}
interface LabelItem {
  id: string;
  createdAt?: string;
  regionalName: string;
  campusId: number;
}
const FormArea: React.FC<FormAreaProps> = ({
  defaultValue,
  visible,
  onClose,
  onOk,
  getInitArea,
}) => {
  const [regionList, setRegionList] = useState<LabelItem[]>([]);
  const [checkItem, setCheckItem] = useState<LabelItem>();
  const reqRegins = async () => {
    const res =
      (await vsf.services.RegionalManagementController_getPagedByRegionalPageQto_b8dd16(
        {
          qto: {
            campusIdIs: vsf.stores.global.currentCampus?.campusId,
            size: 100,
            from: 0,
          },
        },
      )) as any;
    if (res.code === 200 || res.data) {
      setRegionList(res.data.result);
    }
  };
  useEffect(() => {
    reqRegins();
  }, []);
  useEffect(() => {
    if (defaultValue && regionList.length) {
      console.log(defaultValue, regionList);
      getInitArea &&
        getInitArea(regionList.find((item) => item.id === defaultValue));
    }
  }, [defaultValue, regionList, getInitArea]);
  return (
    <MyPopup
      title="选择区域"
      visible={visible}
      onClose={onClose}
      onOk={() => onOk && onOk(checkItem)}
    >
      <div className="area-content">
        <Radio.Group
          onChange={(val) =>
            setCheckItem(regionList.find((item) => item.id === val))
          }
          value={checkItem?.id}
          defaultValue={defaultValue}
        >
          <Space direction="vertical">
            {regionList.map((item) => (
              <Radio key={item.id} value={item.id}>
                {item.regionalName}
              </Radio>
            ))}
          </Space>
        </Radio.Group>
      </div>
    </MyPopup>
  );
};
export default FormArea;

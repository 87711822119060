import './index.less';

import vsf, { definePage } from '@vs/vsf-boot';
import { Button, Checkbox, Grid, SearchBar, Space } from '@vs/vsf-mobile';
import { pinyin } from 'pinyin-pro';
import React, { useEffect, useRef, useState } from 'react';

import collapseDown from '@/assets/icons/collapse-down.png';
import check from '@/assets/images/check.png';
import check1 from '@/assets/images/check1.png';
import Deltag from '@/modal/delTag';
import MyEmpty from '@/modal/myEmpty';
import MyPopup from '@/modal/myPopup';
import { TagListInput } from '@/modal/tagFormItem';

interface userProps {
  userId: string;
  name: string;
  mobile: string;
}
interface FormMemberProps {
  visible?: boolean;
  onClose?: () => void;
  onOk?: (params: userProps[]) => void;
  stores?: any;
  defaultValue?: any;
  value?: any[];
  onChange?: (value: any[]) => void;
  title?: string;
}

const FormMember: React.FC<FormMemberProps> = ({
  visible,
  onClose,
  onOk,
  stores,
  defaultValue,
  value = [],
  onChange,
  title,
}) => {
  const { global } = stores || {};
  const { userInfo, isMobileDevice, currentCampus } = global || {};
  const [searchName, setSearchName] = useState<string | undefined>();
  const [userList, setUserList] = useState<userProps[]>([]);
  const [allUserList, setAllUserList] = useState<userProps[]>([]);
  const [member, setMember] = useState<userProps[]>([]);
  const myRef = useRef<HTMLDivElement | null>(null);
  const [collapse, setCollapse] = useState(false);
  const [showCollapse, setShowCollapse] = useState(false);
  const removeTag = (key) => {
    setMember(member.filter((v) => v.userId !== key && v.mobile !== key));
  };

  const queryOrganizeUser = async () => {
    const res = await vsf.services.ApiController_queryOrganizeUser_97de9d({
      userId: userInfo.userId,
      campusId: currentCampus.campusId,
      name: searchName,
      // appUserId: userInfo.userId
    });
    if (res.code === 200 && res.data.success) {
      if (!searchName) {
        setAllUserList(res.data.data || []);
      }
      setUserList(res.data.data || []);
    }
  };
  const onCheckChange = (v, u) => {
    if (v && u) {
      setMember([
        userList.find((user) =>
          !u.mobile ? user.userId === u.userId : user.mobile === u.mobile,
        ) as userProps,
        ...member,
      ]);
    } else {
      setMember(
        member.filter((item) =>
          !u.mobile ? item?.userId !== u.userId : item.mobile !== u.mobile,
        ),
      );
    }
  };
  const sortUser = () => {
    // 使用pinyin库获取首字母并进行排序
    // 按照中文拼音进行排序
    userList.sort((a, b) => {
      return pinyin(a.name).localeCompare(pinyin(b.name), 'zh-Hans-CN', {
        sensitivity: 'accent',
      });
    });

    // 分组并插入首字母
    const result: any = [];
    let currentLetter = '';

    userList.forEach((item) => {
      const firstLetterPinyin = pinyin(item.name, {
        pattern: 'first',
        toneType: 'none',
      }).toUpperCase()[0];
      if (firstLetterPinyin !== currentLetter) {
        currentLetter = firstLetterPinyin;
        result.push(currentLetter);
      }
      result.push(item);
    });

    return result;
  };
  useEffect(() => {
    queryOrganizeUser();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    if (value && value.length && allUserList.length && !member.length) {
      setMember(
        allUserList.filter((m) =>
          value.find((user) =>
            !user.mobile ? user.userId === m.userId : user.mobile === m.mobile,
          ),
        ),
      );
      onChange &&
        onChange(
          allUserList.filter((m) =>
            value.find((user) =>
              !user.mobile
                ? user.userId === m.userId
                : user.mobile === m.mobile,
            ),
          ),
        );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value, allUserList]);
  useEffect(() => {
    // 当组件挂载后，ref.current将指向相关的DOM节点，可以获取其高度
    if (myRef.current) {
      const height = myRef.current.clientHeight;
      setShowCollapse(height > 34);
    }
  }, [member]); // 空依赖数组确保effect只在挂载时运行

  return (
    <>
      <TagListInput
        value={value
          ?.filter((m) =>
            allUserList.find((user) =>
              !user.mobile
                ? user.userId === m.userId
                : user.mobile === m.mobile,
            ),
          )
          ?.map((item) => item?.name)}
      />
      <MyPopup
        title={title || '选择负责人'}
        visible={visible}
        onClose={onClose}
        onOk={() => {
          onChange && onChange(member);
          setSearchName(undefined);
          setUserList(allUserList);
          setCollapse(false);
          onClose && onClose();
        }}
      >
        <div className="form-member-content">
          {member.length ? (
            <div className="tag-list-box">
              <div className={!collapse ? 'member-tag-list' : undefined}>
                <div ref={myRef}>
                  <Space
                    style={{ '--gap': '11px' }}
                    direction="horizontal"
                    wrap
                  >
                    {member?.map((item) => {
                      return (
                        <Deltag
                          key={item.mobile || item.userId}
                          title={item.name}
                          onRemove={() => removeTag(item.mobile || item.userId)}
                        />
                      );
                    })}
                  </Space>
                </div>
              </div>
              {showCollapse ? (
                <div
                  onClick={() => setCollapse(!collapse)}
                  className={`collapse`}
                >
                  {collapse ? '收起' : '展开'}
                  <img
                    className={`${collapse ? 'up' : 'down'}`}
                    src={collapseDown}
                    alt=""
                  />
                </div>
              ) : null}
            </div>
          ) : null}
          <div className="member-search">
            <SearchBar
              icon={null}
              placeholder="请输入姓名"
              style={{ '--background': '#ffffff', '--border-radius': '19px' }}
              value={searchName}
              onChange={setSearchName}
            />
            <Button onClick={queryOrganizeUser} className="member-search-btn">
              搜索
            </Button>
          </div>
          {userList.length ? (
            <div className="member-list">
              <Space direction="vertical">
                {!searchName ? (
                  <Checkbox
                    style={{ '--icon-size': '14px', '--font-size': '14px' }}
                    icon={(checked, indeterminate) => {
                      return (
                        <img
                          className="check-icon"
                          src={checked ? check1 : check}
                        ></img>
                      );
                    }}
                    indeterminate={
                      member.length > 0 && member.length < userList.length
                    }
                    checked={member.length === userList.length}
                    onChange={(checked) => {
                      if (checked) {
                        setMember(userList);
                      } else {
                        setMember([]);
                      }
                    }}
                  >
                    <span className="check-all">全选</span>
                  </Checkbox>
                ) : null}
                <Checkbox.Group
                  value={member.map((item) => item.mobile || item.userId)}
                  // onChange={onCheckChange}
                >
                  <Space direction="vertical">
                    {sortUser().map((item) => {
                      if (item.mobile || item.userId) {
                        return (
                          <Checkbox
                            key={item.mobile || item.userId}
                            style={{
                              '--icon-size': '14px',
                              '--font-size': '14px',
                            }}
                            icon={(checked, indeterminate) => {
                              return (
                                <img
                                  className="check-icon"
                                  src={checked ? check1 : check}
                                ></img>
                              );
                            }}
                            value={item.mobile || item.userId}
                            onChange={(val) => onCheckChange(val, item)}
                          >
                            <span className="check-label">{item?.name}</span>
                          </Checkbox>
                        );
                      }
                      return (
                        <div key={item} className="letter">
                          {item}
                        </div>
                      );
                    })}
                  </Space>
                </Checkbox.Group>
              </Space>
            </div>
          ) : (
            <MyEmpty text="当前无数据" />
          )}
        </div>
      </MyPopup>
    </>
  );
};
export default definePage(FormMember);

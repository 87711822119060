import { createServices } from '@vs/vsf-boot';
export default createServices({
  VisitorController_getAllByVisitorCenterQto_4cf845: {
    method: 'post',
    url: '/api/visitor-center-info/get-all-by-visitor-center-qto',
    parameterFomatter: (data?: { qto: VisitorCenterQto140F59 }) => data,
    responseFormatter: (
      _,
      __,
      data?: {
        count: number;
        result: VisitorPageVo1B752B[];
        from: number;
        size: number;
        scrollId: string;
        hasMore: boolean;
      },
    ) => data,
  },
  VisitorController_create_ebe62f: {
    method: 'post',
    url: '/api/visitor-center-info/create',
    parameterFomatter: (data?: { btoParam: CreateBtoDcc242 }) => data,
    responseFormatter: (_, __, data?: number) => data,
  },
  VisitorController_getByIdAndCampusId_6b9aad: {
    method: 'post',
    url: '/api/visitor-center-info/get-by-id',
    parameterFomatter: (data?: { var: VisitorCenterIdCampusIdEoB9216E }) =>
      data,
    responseFormatter: (_, __, data?: VisitorIdVo1B752B) => data,
  },
  VisitorController_getCaptcha_c9b8c5: {
    method: 'post',
    url: '/api/visitor-center-info/get-captcha',
    parameterFomatter: (data?: { cellPhone: string }) => data,
    responseFormatter: (_, __, data?: boolean) => data,
  },
});

import './index.less';

import vsf from '@vs/vsf-boot';
import { FormItem, Space, Tag } from '@vs/vsf-mobile';
import React, { ReactElement } from 'react';

interface TagListInputProps {
  value?: string[];
  onChange?: (value: string[]) => void;
}
interface MyFormItemProps {
  name?: string;
  label?: string;
  required?: boolean;
  rules?: object[];
  className?: string;
  onClick?: () => void;
  children: ReactElement;
}
export const TagListInput: React.FC<TagListInputProps> = ({
  value,
  onChange,
}) => {
  return value?.length ? (
    <div className="tag-list">
      <Space style={{ '--gap': '16px' }} wrap>
        {value
          .filter((item) => !!item)
          .map((item, index) => (
            <Tag key={item + index} className="my-tag">
              {item}
            </Tag>
          ))}
      </Space>
    </div>
  ) : null;
};
const MyFormItem: React.FC<MyFormItemProps> = ({
  name,
  label,
  required,
  rules,
  className,
  children,
  onClick,
}) => {
  return (
    <>
      <FormItem
        className="tags-label"
        name={name}
        label={label}
        required={required}
        onClick={onClick}
      ></FormItem>
      <FormItem name={name} className={`tags-input ${className}`} rules={rules}>
        {children}
      </FormItem>
    </>
  );
};
export default MyFormItem;

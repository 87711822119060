import './index.less';
import vsf, { definePage } from '@vs/vsf-boot';
import { Box, List, NavBar } from '@vs/vsf-mobile';
import DownloadQRCode from '@/modal/downloadQRCode';
import React, { useEffect } from 'react';
const Code = (props) => {
  const { global } = props?.stores || {};
  const { currentCampus } = global || {};
  return (
    <>
      <NavBar children={currentCampus.appInfoName + "访客二维码"} backArrow={true} onBack={() => vsf.goBack()}/>
      <Box className='vistor-code'>
        <DownloadQRCode value={window.location.protocol + '//' + window.location.host + '/visitor_create?campusId=' + currentCampus.campusId} size={128} fileName={currentCampus.appInfoName + "访客二维码"} />
      </Box>
    </>
  );
};

export default definePage(Code);

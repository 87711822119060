import { useEffect, useState } from 'react';

/**
 * 监听移动端软键盘弹起
 * @returns
 */
const useMonitorSoftKeyboard = () => {
  // 记录软键盘是否降下（收起）， 默认是收起的
  const [isDownSoftKeyboard, setIsDownSoftKeyboard] = useState<boolean>(true);

  useEffect(() => {
    // 初始化页面之后，记录原窗口高度
    const originalHeight =
      document.documentElement.clientHeight || document.body.clientHeight;

    const monitorSoftKeyboard = () => {
      // 键盘弹起与隐藏都会引起窗口的高度发生变化
      const resizeHeight =
        document.documentElement.clientHeight || document.body.clientHeight;
      // 由于IOS大屏手机特殊原因，弹起软键盘再恢复的大小和一开始的有落差，可能比原来小，或者比原来大，差值20符合大部分情况，
      // 而大部分键盘弹起的高度远不止20，所以无其他影响，为兼容部分IOS处理
      const acceptableRange = 20;
      if (Math.abs(originalHeight - resizeHeight) < acceptableRange) {
        // 初次记录的原始高度 和 后面变化resizeHeight，如果差值小于20，则表示软键盘已收起
        setIsDownSoftKeyboard(true);
      } else {
        // 软键盘弹起
        setIsDownSoftKeyboard(false);
      }
    };

    window.addEventListener('resize', monitorSoftKeyboard);

    return () => {
      window.removeEventListener('resize', monitorSoftKeyboard);
    };
  }, []);

  return { isDownSoftKeyboard, isUpSoftKeyboard: !isDownSoftKeyboard } as {
    isDownSoftKeyboard: boolean;
    isUpSoftKeyboard: boolean;
  };
};

export default useMonitorSoftKeyboard;

import { createServices } from '@vs/vsf-boot';
export default createServices({
  LabelController_deleteLabel_ebd15a: {
    method: 'post',
    url: '/api/regional-management/delete-label',
    parameterFomatter: (data?: { btoParam: DeleteLabelBtoE90B92 }) => data,
    responseFormatter: (_, __, data?: boolean) => data,
  },
  LabelController_createLabel_4cc3e6: {
    method: 'post',
    url: '/api/regional-management/create-label',
    parameterFomatter: (data?: { labels: string[] }) => data,
    responseFormatter: (_, __, data?: boolean) => data,
  },
  RegionalManagementController_deleteLabelInfo_c7c53c: {
    method: 'post',
    url: '/api/regional-management/delete-label-info',
    parameterFomatter: (data?: {
      btoParam: DeleteLabelBtoE90B92;
      extApiParam: any;
    }) => data,
    responseFormatter: (_, __, data?: LabelInfoPageVo58057A) => data,
  },
  RegionalManagementController_createRegionalInfo_1c6178: {
    method: 'post',
    url: '/api/regional-management/create-regional-info',
    parameterFomatter: (data?: {
      btoParam: CreateRegionalBtoE90B92;
      extApiParam: any;
    }) => data,
    responseFormatter: (_, __, data?: RegionalPageVo58057A) => data,
  },
  RegionalManagementController_updateRegionalInfo_fd2c58: {
    method: 'post',
    url: '/api/regional-management/update-regional-info',
    parameterFomatter: (data?: {
      btoParam: UpdateRegionalBtoE90B92;
      extApiParam: any;
    }) => data,
    responseFormatter: (_, __, data?: RegionalPageVo58057A) => data,
  },
  RegionalManagementController_deleteRegionalInfo_7adfb5: {
    method: 'post',
    url: '/api/regional-management/delete-regional-info',
    parameterFomatter: (data?: {
      btoParam: DeleteRegionalBtoE90B92;
      extApiParam: any;
    }) => data,
    responseFormatter: (_, __, data?: RegionalPageVo58057A) => data,
  },
  RegionalManagementController_getPagedByRegionalPageQto_b8dd16: {
    method: 'post',
    url: '/api/regional-management/get-paged-by-regional-page-qto',
    parameterFomatter: (data?: { qto: RegionalPageQtoB1912C }) => data,
    responseFormatter: (
      _,
      __,
      data?: {
        count: number;
        result: RegionalPageVo58057A[];
        from: number;
        size: number;
        scrollId: string;
        hasMore: boolean;
      },
    ) => data,
  },
  RegionalManagementController_getRegionalAllVoById_50b37b: {
    method: 'post',
    url: '/api/regional-management/get-regional-all-vo-by-id',
    parameterFomatter: (data?: { id: number }) => data,
    responseFormatter: (_, __, data?: RegionalAllVo58057A) => data,
  },
  PlaceInfoController_createPlaceInfo_d3a635: {
    method: 'post',
    url: '/api/regional-management/create-place-info',
    parameterFomatter: (data?: {
      btoParam: CreatePlaceBtoE90B92;
      extApiParam: any;
    }) => data,
    responseFormatter: (_, __, data?: PlaceInfoIdVo58057A) => data,
  },
  PlaceInfoController_updatePlaceInfo_649ca6: {
    method: 'post',
    url: '/api/regional-management/update-place-info',
    parameterFomatter: (data?: {
      btoParam: UpdatePlaceBtoE90B92;
      extApiParam: any;
    }) => data,
    responseFormatter: (_, __, data?: PlaceInfoIdVo58057A) => data,
  },
  PlaceInfoController_deletePlaceInfo_12f8f2: {
    method: 'post',
    url: '/api/regional-management/delete-place-info',
    parameterFomatter: (data?: {
      btoParam: DeletePlaceBtoE90B92;
      extApiParam: any;
    }) => data,
    responseFormatter: (_, __, data?: PlaceInfoIdVo58057A) => data,
  },
  PlaceInfoController_getPlaceInfoIdVoById_a14415: {
    method: 'post',
    url: '/api/regional-management/get-place-info-id-vo-by-id',
    parameterFomatter: (data?: { id: number }) => data,
    responseFormatter: (_, __, data?: PlaceInfoIdVo58057A) => data,
  },
  RegionalManagementController_createBatchLabelInfo_f69ce4: {
    method: 'post',
    url: '/api/regional-management/create-batch-label-info',
    parameterFomatter: (data?: { btoParam: CreateLabelBtoE90B92[] }) => data,
    responseFormatter: (_, __, data?: boolean) => data,
  },
  RegionalManagementController_createLabelInfo_ad803e: {
    method: 'post',
    url: '/api/regional-management/create-label-info',
    parameterFomatter: (data?: {
      btoParam: CreateLabelBtoE90B92;
      extApiParam: any;
    }) => data,
    responseFormatter: (_, __, data?: LabelInfoPageVo58057A) => data,
  },
  RegionalManagementController_getPagedByLabelInfoPageQto_25249d: {
    method: 'post',
    url: '/api/regional-management/get-paged-by-label-info-page-qto',
    parameterFomatter: (data?: { qto: LabelInfoPageQtoB1912C }) => data,
    responseFormatter: (
      _,
      __,
      data?: {
        count: number;
        result: LabelInfoPageVo58057A[];
        from: number;
        size: number;
        scrollId: string;
        hasMore: boolean;
      },
    ) => data,
  },
  RegionalManagementController_getRegionalAllVoByRegionalNameAndCampusId_65ffb3:
    {
      method: 'post',
      url: '/api/regional-management/get-regional-all-vo-by-regional-name-and-campus-id',
      parameterFomatter: (data?: {
        var: RegionalInfoRegionalNameCampusIdEo258923;
      }) => data,
      responseFormatter: (_, __, data?: RegionalAllVo58057A) => data,
    },
  PlaceInfoController_batchCreatePlaceInfo_32ce16: {
    method: 'post',
    url: '/api/regional-management/batch-create-place-info',
    parameterFomatter: (data?: {
      btoParam: CreatePlaceBtoE90B92[];
      extApiParam: {
        userId: string;
        campusId: string;
        lead: boolean;
      };
    }) => data,
    responseFormatter: (_, __, data?: boolean) => data,
  },
  RegionalManagementController_getListPlaceInfoIdVoByRegionalId_24cfcb: {
    method: 'post',
    url: '/api/regional-management/get-list-place-info-id-vo-by-regional-id',
    parameterFomatter: (data?: { regionalId: number }) => data,
    responseFormatter: (_, __, data?: PlaceInfoIdVo58057A[]) => data,
  },
  OssController_getOssPolicy_84844c: {
    method: 'post',
    url: '/api/external-api/get-oss-policy',
    parameterFomatter: (data?: any) => data,
    responseFormatter: (_, __, data?: OssPolicyVo9C3D80) => data,
  },
  ApiController_queryUserInfo_5303fe: {
    method: 'post',
    url: '/api/external-api/query-user-info',
    parameterFomatter: (data?: { code: string }) => data,
    responseFormatter: (_, __, data?: string) => data,
  },
  ApiController_queryCampusInfo_e1e515: {
    method: 'post',
    url: '/api/external-api/query-campus-info',
    parameterFomatter: (data?: { userId: string }) => data,
    responseFormatter: (_, __, data?: string) => data,
  },
  ApiController_queryIsAdmin_e24a5b: {
    method: 'post',
    url: '/api/external-api/query-is-admin',
    parameterFomatter: (data?: { userId: string; deptName: string }) => data,
    responseFormatter: (_, __, data?: string) => data,
  },
  ApiController_queryOrganizeUser_97de9d: {
    method: 'post',
    url: '/api/external-api/query-organize-user',
    parameterFomatter: (data?: {
      userId: string;
      campusId: string;
      name: string;
      appUserId: string;
    }) => data,
    responseFormatter: (_, __, data?: string) => data,
  },
  ApiController_ossUpload_248b32: {
    method: 'post',
    url: '/api/external-api/oss-upload',
    parameterFomatter: (data?: { fileName: string }) => data,
    responseFormatter: (_, __, data?: JSONObject) => data,
  },
  ApiController_getFileUrl_a34786: {
    method: 'post',
    url: '/api/external-api/get-file-url',
    parameterFomatter: (data?: { fileIds: string }) => data,
    responseFormatter: (_, __, data?: JSONObject) => data,
  },
  InspectionCenterInfoController_createInspectionRecord_364494: {
    method: 'post',
    url: '/api/inspection-center-info/create-inspection-record',
    parameterFomatter: (data?: {
      btoParam: CreateInspectionRecordBtoD96A80;
      extApiParam: any;
    }) => data,
    responseFormatter: (_, __, data?: InspectionRecordVoBccc51) => data,
  },
  InspectionCenterInfoController_updateInspectionRecord_20f8d9: {
    method: 'post',
    url: '/api/inspection-center-info/update-inspection-record',
    parameterFomatter: (data?: {
      btoParam: UpdateInspectionRecordBtoD96A80;
      extApiParam: any;
    }) => data,
    responseFormatter: (_, __, data?: InspectionRecordVoBccc51) => data,
  },
  InspectionCenterInfoController_getPagedByInspectionRecordQto_d2cadd: {
    method: 'post',
    url: '/api/inspection-center-info/get-paged-by-inspection-record-qto',
    parameterFomatter: (data?: { qto: InspectionRecordQto6C618E }) => data,
    responseFormatter: (
      _,
      __,
      data?: {
        count: number;
        result: InspectionRecordVoBccc51[];
        from: number;
        size: number;
        scrollId: string;
        hasMore: boolean;
      },
    ) => data,
  },
  InspectionCenterInfoController_createComment_e93231: {
    method: 'post',
    url: '/api/inspection-center-info/create-comment',
    parameterFomatter: (data?: {
      btoParam: CreateCommentBtoD96A80;
      extApiParam: any;
    }) => data,
    responseFormatter: (_, __, data?: CommentVoBccc51) => data,
  },
  InspectionCenterInfoController_queryAreaStatistics_b8534b: {
    method: 'post',
    url: '/api/inspection-center-info/query-area-statistics',
    parameterFomatter: (data?: { areaStatisticsBvo: StatisticsEo5Bb7A7 }) =>
      data,
    responseFormatter: (_, __, data?: AreaResultVoBccc51[]) => data,
  },
  InspectionCenterInfoController_queryGradeStatistics_38e03b: {
    method: 'post',
    url: '/api/inspection-center-info/query-grade-statistics',
    parameterFomatter: (data?: { qto: StatisticsEo5Bb7A7 }) => data,
    responseFormatter: (_, __, data?: AreaResultVoBccc51[]) => data,
  },
  InspectionCenterInfoController_queryLabelStatistics_b219e3: {
    method: 'post',
    url: '/api/inspection-center-info/query-label-statistics',
    parameterFomatter: (data?: {
      labelStatisticsQto: LabelStatisticsQto6C618E;
    }) => data,
    responseFormatter: (_, __, data?: LabelStatisticsResultVoBccc51[]) => data,
  },
  InspectionCenterInfoController_exportExcel_20af6b: {
    method: 'post',
    url: '/api/inspection-center-info/export-excel',
    parameterFomatter: (data?: {
      response: HttpServletResponse;
      qto: StatisticsEo5Bb7A7;
    }) => data,
    responseFormatter: (_, __, data?: any) => data,
  },
  InspectionCenterInfoController_getPlaceGroup_7b120c: {
    method: 'post',
    url: '/api/inspection-center-info/get-paged-by-inspection-record',
    parameterFomatter: (data?: { qto: InspectionRecordAllQto6C618E }) => data,
    responseFormatter: (_, __, data?: InspectionGroupVoBccc51[]) => data,
  },
  InspectionCenterInfoController_getListInspectionRecordVoByPlace_7a7e13: {
    method: 'post',
    url: '/api/inspection-center-info/get-list-inspection-record-vo-by-place',
    parameterFomatter: (data?: { place: string }) => data,
    responseFormatter: (_, __, data?: InspectionRecordVoBccc51[]) => data,
  },
  InspectionCenterInfoController_queryGradeList_8c94dd: {
    method: 'post',
    url: '/api/inspection-center-info/query-grade-list',
    parameterFomatter: (data?: { qto: StatisticsEo5Bb7A7 }) => data,
    responseFormatter: (_, __, data?: AreaResultVoBccc51[]) => data,
  },
  InspectionCenterInfoController_deleteInspectionRecord_a331a8: {
    method: 'post',
    url: '/api/inspection-center-info/delete-inspection-record',
    parameterFomatter: (data?: {
      btoParam: DeleteInspectionRecordBtoD96A80;
      extApiParam: any;
    }) => data,
    responseFormatter: (_, __, data?: InspectionRecordVoBccc51) => data,
  },
  InspectionCenterInfoController_getLabelStatistics_3a7788: {
    method: 'post',
    url: '/api/inspection-center-info/get-label-statistics',
    parameterFomatter: (data?: { qto: RecordLabelQto6C618E }) => data,
    responseFormatter: (_, __, data?: LabelStatisticsResultVoBccc51[]) => data,
  },
  InspectionTeamController_createInspectionTeam_30a69c: {
    method: 'post',
    url: '/api/inspection-team/create-inspection-team',
    parameterFomatter: (data?: {
      btoParam: CreateInspectionTeamBtoF38511;
      extApiParam: any;
    }) => data,
    responseFormatter: (_, __, data?: InspectionTeamVo6Dabe5) => data,
  },
  InspectionTeamController_updateInspectionTeam_8c1a8d: {
    method: 'post',
    url: '/api/inspection-team/update-inspection-team',
    parameterFomatter: (data?: {
      btoParam: UpdateInspectionTeamBtoF38511;
      extApiParam: any;
    }) => data,
    responseFormatter: (_, __, data?: InspectionTeamVo6Dabe5) => data,
  },
  InspectionTeamController_deleteInspectionTeam_afe6c2: {
    method: 'post',
    url: '/api/inspection-team/delete-inspection-team',
    parameterFomatter: (data?: {
      btoParam: DeleteInspectionTeamBtoF38511;
      extApiParam: any;
    }) => data,
    responseFormatter: (_, __, data?: InspectionTeamVo6Dabe5) => data,
  },
  InspectionTeamController_getInspectionTeamVoById_b90cb3: {
    method: 'post',
    url: '/api/inspection-team/get-inspection-team-vo-by-id',
    parameterFomatter: (data?: { id: number }) => data,
    responseFormatter: (_, __, data?: InspectionTeamVo6Dabe5) => data,
  },
  InspectionTeamController_getPagedByInspectionTeamSimpleQto_75cd2c: {
    method: 'post',
    url: '/api/inspection-team/get-paged-by-inspection-team-simple-qto',
    parameterFomatter: (data?: { qto: InspectionTeamSimpleQto4C6F30 }) => data,
    responseFormatter: (
      _,
      __,
      data?: {
        count: number;
        result: InspectionTeamSimpleVo6Dabe5[];
        from: number;
        size: number;
        scrollId: string;
        hasMore: boolean;
      },
    ) => data,
  },
  InspectionTeamController_queryPlaceTeam_ee6993: {
    method: 'post',
    url: '/api/inspection-team/query-place-team',
    parameterFomatter: (data?: { placeId: number }) => data,
    responseFormatter: (_, __, data?: InspectionTeamSimpleDto6718D3[]) => data,
  },
  InspectionTeamController_queryTeamLabel_97b580: {
    method: 'post',
    url: '/api/inspection-team/query-team-label',
    parameterFomatter: (data?: { teamId: number; labelName: string }) => data,
    responseFormatter: (_, __, data?: LabelInfoPageDto342Eb9[]) => data,
  },
  InspectionCenterInfoController_getListInspectionRecordVoByPlaceId_bf7e58: {
    method: 'post',
    url: '/api/inspection-center-info/get-list-inspection-record-vo-by-place-id',
    parameterFomatter: (data?: { placeId: number }) => data,
    responseFormatter: (_, __, data?: InspectionRecordVoBccc51[]) => data,
  },
  InspectionCenterInfoController_getInspectionRecordVoById_ecb410: {
    method: 'post',
    url: '/api/inspection-center-info/get-inspection-record-vo-by-id',
    parameterFomatter: (data?: { id: number }) => data,
    responseFormatter: (_, __, data?: InspectionRecordVoBccc51) => data,
  },
});

import vsf, { defineStore } from '@vs/vsf-boot';

import storage from '@/utils/storage';

const global = defineStore({
  userInfo: storage.get('userInfo') || {},
  isAdmin: storage.get('isAdmin') || false,
  currentCampus: storage.get('currentCampus') || {}, // 当前校区
  campusList: storage.get('campusList') || [], //校区列表
  inspectionAnalysisData: { timeLimit1: 0, timeLimit2: 0 },
  isMobileDevice: storage.get('isMobileDevice') || false,
  loading: false,
  corpInfo: storage.get('corpInfo') || { init: false },
  setCorpinfo: (v) => {
    global.corpInfo = v;
    storage.set('corpInfo', v);
  },
  setUserInfo: (v) => {
    global.userInfo = v;
    storage.set('userInfo', v);
  },
  setIsAmdin: (v) => {
    global.isAdmin = v;
    storage.set('isAdmin', v);
  },
  setCurrentCampus: (v) => {
    global.currentCampus = v;
    storage.set('currentCampus', v);
  },
  setCampusList: (v) => {
    global.campusList = v;
    storage.set('campusList', v);
  },
  setInspectionAnalysisData: (v) => {
    global.inspectionAnalysisData = v;
  },
  setLoading: (v) => {
    global.loading = v;
  },
  initInspectionAnalysisData: (v) => {
    global.inspectionAnalysisData = { timeLimit1: 0, timeLimit2: 0 };
  },
  initIsMobileDevice: () => {
    const isMobileDevice =
      /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
        navigator.userAgent,
      );
    global.isMobileDevice = isMobileDevice;
    storage.set('isMobileDevice', isMobileDevice);
  },
  resetData: () => {
    console.log('resetData');
    global.setUserInfo({});
    global.setIsAmdin(false);
    global.setCurrentCampus({});
    global.setCampusList([]);
    global.setLoading(false);
    global.setCorpinfo({ ...global.corpInfo, init: false });
  },
});

export default global;

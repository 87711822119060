import './index.less';

import vsf, { definePage, renderRoutes } from '@vs/vsf-boot';
import { Mask, TabBar, TabBarItem } from '@vs/vsf-mobile';
import React, { useEffect } from 'react';

import { APP_KEY, LOGIN_TEXT_URL, LOGIN_URL } from '@/config';
import { RouteWithTab } from '@/router';

type ContainerProps = {
  route: RouteWithTab;
  location: Location;
};
let UNLISTEN;
let UNLISTEN1;

const Container: React.FC<ContainerProps> = (props: any) => {
  const { global } = props?.stores || {};
  const {
    initIsMobileDevice,
    userInfo,
    isAdmin,
    initInspectionAnalysisData,
    setUserInfo,
    setIsAmdin,
    setCurrentCampus,
    setCampusList,
    currentCampus,
    resetData,
    loading,
    setLoading,
    corpInfo,
    setCorpinfo,
  } = global || {};
  const { route } = props;
  const { pathname } = props.location;
  function scrollToTopOfDiv() {
    const div = document.getElementById('layout_main');
    if (div) {
      div.scrollTop = 0; // 将 div 的滚动位置设置为 0
    }
  }
  function addQueryParam(url, paramName, paramValue) {
    const urlObject = new URL(url);
    urlObject.searchParams.set(paramName, paramValue);
    return urlObject.href;
  }

  const login = (loginOut?: string) => {
    if (!corpInfo.init && !loginOut) return;
    const state = null;
    const action = loginOut || null;
    const sid = props.routes.query.sid || corpInfo.sid;
    const corpid = props.routes.query.corpid || corpInfo.corpid;
    const corp = props.routes.query.corp || corpInfo.corp;
    // if (sid || corpid || corp) setCorpinfo({ sid, corpid, corp });
    let redirect_uri = window.location.href;
    if (window.location.pathname === '/login')
      redirect_uri = window.location.protocol + '//' + window.location.host;
    if (window.location.protocol !== 'https:')
      redirect_uri = 'https://safe-campus.byteawake.com/oauth_login';
    const urlObject = new URL(LOGIN_URL);
    APP_KEY && urlObject.searchParams.set('client_id', APP_KEY);
    sid && urlObject.searchParams.set('sid', sid);
    state && urlObject.searchParams.set('state', state);
    corp && urlObject.searchParams.set('corp', corp);
    corpid && urlObject.searchParams.set('corpid', corpid);
    action && urlObject.searchParams.set('action', action);
    redirect_uri && urlObject.searchParams.set('redirect_uri', redirect_uri);
    window.location.href = urlObject.href;
  };

  // 路由权限控制
  const getRoleRoute = () => {
    // vsf.showToast(`${corpInfo.sid}, ${corpInfo.corpid}, ${corpInfo.corp}`);
    if (props.routes.query.code || loading) return;
    const pathname = window.location.pathname;
    if (
      !userInfo?.userId &&
      pathname !== '/visitor_create' &&
      pathname !== '/login' &&
      // pathname !== '/InspectionRecordDetail' &&
      pathname !== '/oauth_login' &&
      pathname !== '/personal'
    ) {
      vsf.showToast('请先登录');
      // vsf.navigateTo('/login');
      login();
    }
    if (
      !isAdmin &&
      userInfo?.userId &&
      pathname !== '/InspectionRecordDetail' &&
      pathname !== '/login' &&
      pathname !== '/visitor_create' &&
      pathname !== '/oauth_login' &&
      pathname !== '/personal'
    ) {
      vsf.showToast('当前账号无权访问');
      // vsf.navigateTo('/login');
    }
  };
  const reqUserInfo = async () => {
    if (!props.routes.query.code) return;
    setLoading(true);
    const res = await vsf.services.ApiController_queryUserInfo_5303fe({
      code: props.routes.query.code,
    });
    if (res.code === 200 && res.data?.success === true) {
      const state = props.routes.query;
      delete state.code;
      console.log(window.location.pathname, state);
      props.history.replace(
        window.location.pathname + '?' + new URLSearchParams(state).toString(),
      );
      setUserInfo(res.data.data);
      console.log('setUserInfo');
      if (res.data.data.userId) {
        Promise.all([
          reqIsAdmin(res.data.data.userId),
          reqCampus(res.data.data.userId),
        ]).finally(() => {
          setLoading(false);
        });
      } else {
        resetData();
        setLoading(false);
      }
      vsf.showToast('登录成功');
    } else {
      resetData();
      setLoading(false);
    }
  };

  const reqIsAdmin = async (userId) => {
    const res = await vsf.services.ApiController_queryIsAdmin_e24a5b({
      userId: userId,
      deptName: '平安校园',
    });
    if (res.code === 200 && res.data?.success === true) {
      setIsAmdin(res.data.data.manager);
    }
  };
  const reqCampus = async (userId) => {
    const res = (await vsf.services.ApiController_queryCampusInfo_e1e515({
      userId,
      userRole: 4,
    })) as any;
    if (res.code === 200 && res.data?.success === true) {
      setCampusList(res.data.data || []);
      if (
        res.data.data.length &&
        (!currentCampus.campusId ||
          !res.data.data.find(
            (item) => item.campusId === currentCampus.campusId,
          ))
      ) {
        setCurrentCampus(res.data.data[0]);
      }
    }
  };

  const corpChange = () => {
    const sid = props.routes.query.sid;
    const corpid = props.routes.query.corpid;
    const corp = props.routes.query.corp;
    if (sid || corp || corpid) {
      setCorpinfo({ sid, corpid, corp, init: true });
    } else {
      setCorpinfo({ ...corpInfo, init: true });
    }
    if (
      (sid || corp || corpid) &&
      (corpInfo.sid || corpInfo.corpid || corpInfo.corp) &&
      (sid !== corpInfo.sid ||
        corp !== corpInfo.corp ||
        corpid !== corpInfo.corpid)
    ) {
      console.log(sid, corpid, corp);
      // setCorpinfo({ sid, corpid, corp });
      resetData();
      vsf.showToast('切换组织需重新登录');
      login('logout');
    }
  };

  useEffect(() => {
    corpChange();
    reqUserInfo();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const isInitInspectionAnalysisData = () => {
    const pathname = window.location.pathname;
    if (
      pathname !== '/InspectionAnalysis' &&
      pathname !== '/InspectionAnalysisDetail'
    ) {
      initInspectionAnalysisData?.();
    }
  };
  const tabs = route.routes?.filter((item) => !!item.tab);
  useEffect(() => {
    initIsMobileDevice?.();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (
      !corpInfo.init ||
      userInfo.userId ||
      props.routes.query.code ||
      loading ||
      window.location.pathname === '/login' ||
      window.location.pathname === '/visitor_create' ||
      window.location.pathname === '/personal'
    )
      return;
    login();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userInfo, loading, corpInfo]);

  useEffect(() => {
    console.log(corpInfo);

    // corpChange();
    if (!corpInfo.init) return;
    getRoleRoute();
    UNLISTEN1 = props.history?.listen(() => {
      getRoleRoute();
      // corpChange();
    });
    return () => {
      UNLISTEN1 && UNLISTEN1();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userInfo, isAdmin, loading, corpInfo]);

  useEffect(() => {
    UNLISTEN = props.history?.listen(() => {
      isInitInspectionAnalysisData();
      scrollToTopOfDiv();
    });
    return () => {
      UNLISTEN && UNLISTEN();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userInfo]);
  if (loading || !corpInfo.init) return null;
  return (
    <div className="vsf-layout">
      <div id="layout_main" className="vsf-layout-main">
        {renderRoutes(
          route.routes.filter((item) => {
            if (!userInfo) {
              return item.isLogin === false;
            } else {
              if (!isAdmin) {
                return item.isAdmin === false;
              }
            }
            return item;
          }),
          route.redirect,
        )}
      </div>
      {tabs?.find((tab) => tab.path === pathname) && (
        <div className="vsf-layout-tabbar">
          <TabBar
            activeKey={pathname}
            onChange={(value) => vsf.navigateTo(value)}
          >
            {tabs?.map((item) => (
              <TabBarItem
                key={item.path}
                icon={item.tab?.icon}
                title={item.tab?.title}
              />
            ))}
          </TabBar>
        </div>
      )}
    </div>
  );
};

export default definePage(Container);

import './index.less';

import vsf from '@vs/vsf-boot';
import { Calendar, DatePickerView, Input, Popup } from '@vs/vsf-mobile';
import { CloseCircleOutline } from 'antd-mobile-icons';
import dayjs from 'dayjs';
import React, { useEffect, useState } from 'react';

import down from '@/assets/icons/timedown.png';

interface MyCalenderPickerProps {
  date?: [Date | string, Date | string] | null;
  timeLimit?: 0 | 1;
  title?: string;
  onOk?: (...values: any[]) => void;
  type?: string;
}
const today = dayjs();
const MAXDATE = dayjs().toDate();
const MyCalenderPicker: React.FC<MyCalenderPickerProps> = ({
  title,
  onOk,
  type,
  date,
  timeLimit,
}) => {
  const [visible, setVisible] = useState(false);
  const [active, setActive] = useState<0 | 1>(timeLimit || 0);
  const [startDate, setStartDate] = useState<Date>(
    today.subtract(6, 'month').toDate(),
  );
  const [endDate, setEndDate] = useState<Date>(today.toDate());
  const [startVisible, setStartVisible] = useState<boolean>(true);
  const [val, setVal] = useState<[Date, Date] | null>([
    today.subtract(6, 'day').toDate(),
    today.toDate(),
  ]);
  const [maxDate, setMaxDate] = useState<Date>(MAXDATE);
  const [minDate, setMinDate] = useState<Date>();
  const onClose = () => {
    setVisible(false);
  };
  const handelOk = () => {
    setVisible(false);
    onOk &&
      onOk(
        active === 0
          ? [
              dayjs(val?.[0]).format('YYYY-MM-DD 00:00:00'),
              dayjs(val?.[1]).format('YYYY-MM-DD 23:59:59'),
            ]
          : [
              dayjs(startDate).format('YYYY-MM-01 00:00:00'),
              dayjs(endDate).endOf('month').format('YYYY-MM-DD 23:59:59'),
            ],
        active,
      );
  };
  const clearDate = () => {
    onOk && onOk(null, 0);
  };
  const handleRangeChange = (selectedDates) => {
    console.log(selectedDates);
    if (selectedDates && selectedDates.length === 2) {
      const start = dayjs(selectedDates[0]);
      const max = start.add(6, 'day');
      const min = start.subtract(6, 'day').toDate();
      if (!max.isAfter(dayjs())) {
        // 如果结束日期超过当前日期，重置结束日期
        setMaxDate(max.toDate());
      } else {
        setMaxDate(MAXDATE);
      }
      setMinDate(min);
    } else {
      setMinDate(undefined);
      setMaxDate(MAXDATE);
    }
    setVal(selectedDates);
  };
  const getDateStr = (date) => {
    return date && date.length
      ? `${dayjs(date[0]).format(
          timeLimit === 1 ? 'YYYY年-MM月' : 'YYYY年-MM月-DD日',
        )}-${dayjs(date[1]).format(
          timeLimit === 1 ? 'YYYY年-MM月' : 'YYYY年-MM月-DD日',
        )}`
      : '';
  };
  useEffect(() => {
    const end = dayjs(startDate).add(6, 'month');
    if (end.isAfter(dayjs())) {
      // 如果结束日期超过当前日期，重置结束日期
      setEndDate(dayjs().toDate());
    } else {
      setEndDate(end.toDate());
    }
  }, [startDate]);
  useEffect(() => {
    console.log('date=====', date);
    if (!date || !date.length) return;
    setActive(timeLimit || 0);
    if (timeLimit === 1) {
      setStartDate(dayjs(date[0]).toDate());
      setEndDate(dayjs(date[1]).toDate());
    } else {
      setVal([dayjs(date[0]).toDate(), dayjs(date[1]).toDate()]);
    }
  }, [date, timeLimit]);
  return (
    <>
      <div className="date-box">
        <div
          className="date-input"
          onClick={() => {
            setVisible(true);
          }}
        >
          {date ? (
            getDateStr(date)
          ) : (
            <div className="placeholder">
              {type !== 'day' ? '选择时间' : '时间筛选'}
            </div>
          )}
        </div>
        {!date || !date?.length ? (
          <img className={visible ? 'open icon' : 'icon'} src={down} alt="" />
        ) : (
          <CloseCircleOutline onClick={clearDate} />
        )}
      </div>
      <Popup
        key={type}
        visible={visible}
        onMaskClick={onClose}
        bodyStyle={{
          borderTopLeftRadius: '14px',
          borderTopRightRadius: '14px',
          minHeight: '40vh',
        }}
        className="my-popup"
        getContainer={() =>
          document.getElementById('layout_main') as HTMLElement
        }
      >
        <div className="popup-header">
          <div className="close" onClick={onClose}>
            取消
          </div>
          <div className="title">{title}</div>
          <div className="ok" onClick={handelOk}>
            确认
          </div>
        </div>
        <div className="popup-content">
          {type !== 'day' ? (
            <div className="tabs">
              <div
                className={active === 0 ? 'tab-item active' : 'tab-item'}
                onClick={() => setActive(0)}
              >
                按日选择
              </div>
              <div
                className={active === 1 ? 'tab-item active' : 'tab-item'}
                onClick={() => setActive(1)}
              >
                按月选择
              </div>
            </div>
          ) : null}
          {active === 0 ? (
            <>
              <div className="time-render">
                <div className="start active">
                  {val && val.length
                    ? dayjs(val[0]).format('YYYY年-MM月-DD日')
                    : null}
                </div>
                <div className="text">至</div>
                <div className="end active">
                  {val && val.length === 2
                    ? dayjs(val[1]).format('YYYY年-MM月-DD日')
                    : null}
                </div>
              </div>
              <Calendar
                className="calendar-custom"
                selectionMode="range"
                value={val}
                max={type !== 'day' ? maxDate : MAXDATE}
                min={type !== 'day' ? minDate : undefined}
                onChange={handleRangeChange}
              />
            </>
          ) : (
            <>
              <div className="time-render">
                <div
                  className={startVisible ? 'start active' : 'start'}
                  onClick={() => {
                    setStartVisible(true);
                  }}
                >
                  {dayjs(startDate).format('YYYY年-MM月')}
                </div>
                <div className="text">至</div>
                <div
                  className={!startVisible ? 'end active' : 'end'}
                  onClick={() => {
                    setStartVisible(false);
                  }}
                >
                  {dayjs(endDate).format('YYYY年-MM月')}
                </div>
              </div>
              {startVisible ? (
                <DatePickerView
                  key="start"
                  onChange={setStartDate}
                  precision="month"
                  defaultValue={startDate}
                  max={MAXDATE}
                  renderLabel={weekdayLabelRenderer}
                />
              ) : (
                <DatePickerView
                  key="end"
                  onChange={setEndDate}
                  precision="month"
                  defaultValue={endDate}
                  max={
                    dayjs(startDate).add(6, 'month').isAfter(dayjs())
                      ? MAXDATE
                      : dayjs(startDate).add(6, 'month').toDate()
                  }
                  min={startDate}
                  renderLabel={weekdayLabelRenderer}
                />
              )}
            </>
          )}
        </div>
      </Popup>
    </>
  );
};
export default MyCalenderPicker;

const weekdayLabelRenderer = (type: string, data: number) => {
  switch (type) {
    case 'year':
      return data + '年';
    case 'month':
      return data + '月';
    default:
      return data;
  }
};

import vsf, { definePage } from '@vs/vsf-boot';
import { NavBar, Box, FormItem, Form, Input, Dialog } from '@vs/vsf-mobile';
import React, { useState, useEffect, useRef } from 'react';
import FixedFormBtn from '@/modal/fixedFormBtn';
import FormMember from '@/modal/formItemMember';
import importSvg from '@/assets/icons/import.svg';
import addLabel from '@/assets/icons/addLabel.png';
import ConfirmMask from '@/modal/confirmMask';
import TagFormItem from '@/modal/tagFormItem';
import PlaceTags from './components/placeTags';
import { fetchUplaod } from '@/utils/upload';
import { extractTables } from '@/utils/xlsx';
import './index.less'

const VistorCreate = (props) => {
  console.log(props.history);
  const { global } = props?.stores || {};
  const {
    userInfo,
    isMobileDevice,
    currentCampus,
  } = global || {};
  console.log(props.routes.query.id);
  const [readOnly, setReadOnly] = useState(false);
  const [visible, setVisible] = useState(false)
  const [regionData, setRegionData] = useState()
  const [maskVisible, setMaskVisible] = useState(false);
  const [userList, setUserList] = useState([]);
  const fileInputRef = useRef(null);

  const toPlace = () => {
    vsf.navigateTo(`/add_place?regionalId=${props.routes.query.id}`);
  }

  const reqRegionDetail = async (params) => {
    if (!props.routes.query.id) return
    //
    // Tips: 以下是你选择的请求函数
    const res =
      await vsf.services?.RegionalManagementController_getRegionalAllVoById_50b37b?.(
        {
          id: props.routes.query.id,
        },
      ); // TODO: 你可能需要整理返回数据格式
    if (res.code === 200) {
      vsf.refs.form3999.setFieldsValue({ ...res.data, regionalPrincipalManBtoList: res.data.regionalPrincipalManDtoList.map(item => ({ ...item, name: item.principalName, mobile: item.principalPhone })) });
      // setRegionalPrincipalManBtoList(res.data.regionalPrincipalManDtoList)
      setRegionData(res.data)
    }
    return res?.data;
  }

  const delRegion = async () => {
    if (!props.routes.query.id) return
    const res =
      await vsf.services?.RegionalManagementController_deleteRegionalInfo_7adfb5?.(
        {
          btoParam: {
            id: props.routes.query.id,
            campusId: regionData.campusId
          }
        },
      ); // TODO: 你可能需要整理返回数据格式
    if (res.code === 200) {
      vsf.showToast('删除成功');
      vsf.goBack();
    }
    return res?.data;
  }

  const handleUploadClick = () => {
    // 触发input的点击事件
    fileInputRef.current.click();
  };

  const strToArr = (str, placeName) => {
    const arr = str.split(',');
    let err = ''
    return [arr.map(item => {
      const placeMan = item.split('-');
      return {
        principalName: placeMan[0],
        principalPhone: placeMan[1],
      }
    }).filter((item, index) => {
      const flag = userList.find(user => user.mobile === item.principalPhone)
      if (!flag) err = err + `${item.principalName}${index === arr.length - 1 ? '' : ','}`
      if (index === arr.length - 1 && err) {
        err = <p>{`场所${placeName}负责人:${err}导入失败!`}</p>;
      };
      return flag;
    }), err];
  }
  const importPlaces = async (values) => {
    const res =
      await vsf.services.PlaceInfoController_batchCreatePlaceInfo_32ce16(
        {
          btoParam: values,
        },
      );
    if (res.code === 200) {
      vsf.showToast('导入成功', 'success');
      reqRegionDetail();
    }
  };
  const customRequest = async (e) => {
    try {
      const response = await extractTables(
        e.file,
        {
          placeType: '*场所类型',
          placeName: '*场所名称',
          placeManBtoList: '*负责人',
        },
        ['placeType', 'placeName', 'placeManBtoList'],
      );
      console.log(response, 'response====');
      if (response) {
        if (response?.[0]?.data && response?.[0]?.data?.length > 0) {
          let resultStr = [];
          importPlaces(response?.[0]?.data.map((item, index) => {
            const placeManBtoList = strToArr(item.placeManBtoList, item.placeName)[0];
            const err = strToArr(item.placeManBtoList, item.placeName)[1];
            err && resultStr.push(err);
            if (index === response?.[0]?.data?.length - 1 && resultStr.length) {
              Dialog.alert({
                content: <div>{resultStr}</div>,
                onConfirm: () => {
                  console.log('导入负责人', resultStr)
                },
              })
            }
            return { ...item, regionalId: props.routes.query.id, placeManBtoList }
          }));
          setTimeout(() => {
            fetchUplaod(e.file);
          }, 1);
        } else {
          vsf.showToast('表格中数据不完整');
        }
      }
    } catch (error) {
      console.error(error);
      vsf.showToast('解析失败，请检查文档格式');
    }
  };
  const handleFileChange = async (event) => {
    console.log(event, 'file changed');
    const file = event.target.files[0];
    if (file) {
      // 检查文件类型
      if (
        file.type ===
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
      ) {
        // 自定义上传逻辑
        await customRequest({ file });
        event.target.value = '';
      } else {
        // 错误处理
        console.error('请上传.xls,.xlsx格式的文件');
      }
    }
  };
  const queryOrganizeUser = async () => {
    const res = await vsf.services.ApiController_queryOrganizeUser_97de9d({
      userId: userInfo.userId,
      campusId: currentCampus.campusId,
    });
    if (res.code === 200 && res.data.success) {
      setUserList(res.data.data || []);
    }
  };

  useEffect(() => {
    reqRegionDetail();
    queryOrganizeUser();
  }, []);

  return (
    <>
      <NavBar
        children="区域管理"
        backArrow={true}
        onBack={() => vsf.goBack()} />

      <Box className="page-region-create">
        <Form
          mode='card'
          vsid="37696"
          id="form3999"
          layout="horizontal"
          requiredMarkStyle='none'
          hasFeedback={true}
          readOnly={readOnly}
          style={{
            "--border-bottom": "1px solid #EBEFF4",
            "--prefix-width": "80px",
            "--text-align": "right"
          }}
          footer={false}
          onFinish={async (params) => {
            if (!props.routes.query.id) {
              const res = await vsf.services?.RegionalManagementController_createRegionalInfo_1c6178?.({
                btoParam: {
                  ...params,
                  campusId: currentCampus.campusId,
                  regionalPrincipalManBtoList: params.regionalPrincipalManBtoList.map(item => ({ principalName: item.name, principalPhone: item.mobile, userId: item.userId }))
                },
              });
              if (res.code === 200) {
                props.history.replace(`/add_region?id=${res.data.id}`)
                reqRegionDetail()
                vsf.showToast('提交成功');
              }
            } else {
              const res = await vsf.services?.RegionalManagementController_updateRegionalInfo_fd2c58?.({
                btoParam: {
                  id: props.routes.query.id,
                  campusId: regionData.campusId,
                  regionalName: params.regionalName,
                  regionalPrincipalManBtoList: params.regionalPrincipalManBtoList?.map(item => ({ principalName: item.name, principalPhone: item.mobile, userId: item.userId }))
                },
              });
              if (res.code === 200) {
                vsf.showToast('提交成功');
                vsf.goBack();
              }
            }
          }}
        >
          <div className='form-box'>
            <FormItem
              name="regionalName"
              label="区域名称"
              rules={[
                { required: true, message: '请输入区域名称' },
                { maxLength: 10, message: '区域名称最多十个字' },
              ]}
            >
              <Input maxLength={10} placeholder="请输入区域名称" />
            </FormItem>
            <TagFormItem
              name="regionalPrincipalManBtoList"
              label="区域负责人"
              required={true}
              rules={[
                { required: true, message: '请选择区域负责人' },
              ]}
              className='last-form-item'
              onClick={readOnly ? undefined : () => setVisible(true)}
            >
              <FormMember visible={visible} onClose={() => setVisible(false)} />
            </TagFormItem>
          </div>
          <Form.Header></Form.Header>
          {props.routes.query.id && <div className='form-box'>
            <PlaceTags name='placeInfoDtoList' label="所含场所" />
            <div className='add-label-btn'>
              {!isMobileDevice ? (
                <div className='import-box'>
                  <div className="import-label" onClick={handleUploadClick}>
                    <img src={importSvg}></img>导入场所
                    <input
                      type="file"
                      ref={fileInputRef}
                      style={{ display: 'none' }}
                      onChange={handleFileChange}
                      accept=".xls,.xlsx"
                    />
                  </div>
                  <a href="/导入场所模板.xlsx">点击下载场所模板</a>
                </div>
              ) : <div></div>}
              <div className='add-label' onClick={toPlace}><img src={addLabel}></img>新增场所</div>
            </div>
          </div>}
        </Form>
        {props.routes.query.id ? <FixedFormBtn
          onConfirm={() => vsf.refs.form3999.submit()}
          restText='删除'
          onReset={() => setMaskVisible(true)}
        /> : !readOnly ? <FixedFormBtn
          onConfirm={() => vsf.refs.form3999.submit()}
          onReset={() => vsf.refs.form3999.resetFields()}
        /> : null}
        <ConfirmMask
          visible={maskVisible}
          text="确定删除该区域吗？"
          onConfirm={delRegion}
          onMaskClick={() => setMaskVisible(false)}
        />
      </Box>
    </>
  );
};
export default definePage(VistorCreate);

import vsf, { definePage } from '@vs/vsf-boot';
import { NavBar, Box, FormItem, Form, Input } from '@vs/vsf-mobile';
import React, { useState, useEffect } from 'react';
import FixedFormBtn from '@/modal/fixedFormBtn';
import MyFormItem from '@/modal/myFormItem';
import FormMember from '@/modal/formItemMember';
import ConfirmMask from '@/modal/confirmMask';
import TagFormItem from '@/modal/tagFormItem';
import FormPlaceType from './components/formPlaceType';
import './index.less'

const VistorCreate = (props) => {
  const [readOnly, setReadOnly] = useState(false);
  const [visible, setVisible] = useState(false)
  const [visible1, setVisible1] = useState(false)
  const [maskVisible, setMaskVisible] = useState(false);
  const formPlaceTypeChange = (value) => {
    console.log(value);
    vsf.refs.form3996.setFieldValue('placeType', value);
    vsf.refs.form3996.validateFields(['placeType']);
    setVisible1(false);
  }
  const reqPlaceDetail = async (params) => {
    if (!props.routes.query.id) return
    //
    // Tips: 以下是你选择的请求函数
    const res =
      await vsf.services?.PlaceInfoController_getPlaceInfoIdVoById_a14415?.(
        {
          id: props.routes.query.id,
        },
      ); // TODO: 你可能需要整理返回数据格式
    if (res.code === 200) {
      vsf.refs.form3996.setFieldsValue({ ...res.data, placeManBtoList: res.data.placeManDtoList.map(item => ({ ...item, name: item.principalName, mobile: item.principalPhone })) });
    }
    return res?.data;
  }

  const delPlace = async () => {
    if (!props.routes.query.id) return
    const res =
      await vsf.services?.PlaceInfoController_deletePlaceInfo_12f8f2?.(
        {
          btoParam: {
            id: props.routes.query.id,
          }
        },
      ); // TODO: 你可能需要整理返回数据格式
    if (res.code === 200) {
      vsf.showToast('删除成功');
      vsf.goBack();
    }
    return res?.data;
  }

  useEffect(() => {
    reqPlaceDetail();
  }, []);

  return (
    <>
      <NavBar
        children={props.routes.query.id ? "编辑场所" : "新增场所"}
        backArrow={true}
        onBack={() => vsf.goBack()} />

      <Box className="page-place-create">
        <Form
          mode='card'
          vsid="37696"
          id="form3996"
          layout="horizontal"
          requiredMarkStyle='none'
          hasFeedback={true}
          style={{
            "--border-bottom": "1px solid #EBEFF4",
            "--prefix-width": "80px",
            "--text-align": "right"
          }}
          footer={false}
          onFinish={async (params) => {
            if (props.routes.query.id) {
              const res = await vsf.services?.PlaceInfoController_updatePlaceInfo_649ca6?.({
                btoParam: {
                  ...params,
                  id: Number(props.routes.query.id),
                  placeManBtoList: params.placeManBtoList.map(item => ({ principalName: item.name, principalPhone: item.mobile, userId: item.userId }))
                },
              });
              if (res.code === 200) {
                vsf.goBack();
                vsf.showToast('提交成功');
              }
            } else {
              const res = await vsf.services?.PlaceInfoController_createPlaceInfo_d3a635?.({
                btoParam: {
                  ...params,
                  regionalId: Number(props.routes.query.regionalId),
                  placeManBtoList: params.placeManBtoList.map(item => ({ principalName: item.name, principalPhone: item.mobile, userId: item.userId }))
                },
              });
              if (res.code === 200) {
                setReadOnly(true);
                vsf.goBack();
                vsf.showToast('提交成功');
              }
            }
          }}
        >
          <div className='form-box'>
            <FormItem
              name="placeType"
              label="场所类型"
              rules={[
                { required: true, message: '请选择场所类型' },
              ]}
              onClick={() => setVisible1(true)}
              className={visible1 ? 'area-form-up' : 'area-form-down'}
            >
              <FormPlaceType visible={visible1} onClose={() => setVisible1(false)} onOk={formPlaceTypeChange} />
            </FormItem>
            <FormItem
              name="placeName"
              label="场所名称"
              rules={[
                { required: true, message: '请输入场所名称' },
                { maxLength: 10, message: '场所名称最多十个字' },
              ]}
            >
              <Input maxLength={10} placeholder="请输入场所名称" />
            </FormItem>
            <TagFormItem
              name="placeManBtoList"
              label="负责人"
              className='last-form-item'
              required={true}
              rules={[
                { required: true, message: '请选择负责人' },
              ]}
              onClick={() => setVisible(true)}
            >
              <FormMember title="选择场所负责人" visible={visible} onClose={() => setVisible(false)} />
            </TagFormItem>
          </div>
        </Form>
        {props.routes.query.id ? <FixedFormBtn
          onConfirm={() => vsf.refs.form3996.submit()}
          restText='删除'
          onReset={() => setMaskVisible(true)}
        /> : !readOnly ? <FixedFormBtn
          onConfirm={() => vsf.refs.form3996.submit()}
          onReset={() => vsf.refs.form3996.resetFields()}
        /> : null}
        <ConfirmMask
          visible={maskVisible}
          text="确定删除该场所吗？"
          onConfirm={delPlace}
          onMaskClick={() => setMaskVisible(false)}
        />
      </Box>
    </>
  );
};
export default definePage(VistorCreate);

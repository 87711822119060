import './index.less';

import vsf, { definePage } from '@vs/vsf-boot';
import axios from 'axios';

import {
  Box,
  Button,
  Divider,
  Form,
  FormItem,
  ImageUploader,
  Input,
  NavBar,
  Picker,
  SearchBar,
  TextArea,
} from '@vs/vsf-mobile';
import React, { useState, useEffect, useCallback } from 'react';

import collapseDown from '@/assets/icons/collapse-down.png';
import labelCheck from '@/assets/icons/labelCheck.png';
import addImg from '@/assets/icons/addImg.png';

import CheckList from '@/modal/checkList';
const InspectionRecordForm = (props) => {
  const type = props?.routes?.query?.type;
  const areaId = props?.routes?.query?.areaId;
  const recordId = props?.routes?.query?.recordId;

  const { global } = props?.stores || {};
  const { userInfo, currentCampus } = global || {};
  const [readOnly, setReadOnly] = useState(false);
  const [visible, setVisible] = useState(false);
  const [placeVisible, setPlaceVisible] = useState(false);
  const [value, setValue] = useState();
  const [reginList, setReginList] = useState([]);
  const [placeList, setPlaceList] = useState();
  const [labelId, setLabelId] = useState([]);
  const [collapse, setCollapse] = useState(false);
  const [labelName, setLabelName] = useState();
  const [teamId, setTeamId] = useState();
  const [placeId, setPlaceId] = useState();

  const [review, setReview] = useState();

  const [labelList, setLabelList] = useState([]);
  const [fileList, setFileList] = useState();
  const [isEdit, setIsEdit] = useState(false);
  const [groupName, setGroupName] = useState();
  const [placeName, setPlaceNmae] = useState();
  const [editItem, setEditItem] = useState();

  const currentDate = new Date();
  const year = currentDate.getFullYear();
  const month = String(currentDate.getMonth() + 1).padStart(2, '0');
  const day = String(currentDate.getDate()).padStart(2, '0');

  const formattedDate = `${year}-${month}-${day}`;

  const handleSetLabelValue = (id) => {
    const index = labelId.indexOf(id);
    if (index !== -1) {
      const newIds = [...labelId];
      newIds.splice(index, 1);
      setLabelId(newIds);
    } else {
      const newIds = [...labelId, id];
      setLabelId(newIds);
    }
  };

  const reqRegins = async (cur) => {
    const res =
      await vsf.services.RegionalManagementController_getPagedByRegionalPageQto_b8dd16(
        {
          qto: {
            campusIdIs: currentCampus?.campusId,
            size: 1000,
            from: 0,
          },
        },
      );
    if (res.code === 200 || res.data) {
      const regins = res?.data?.result?.map((item) => {
        return {
          label: item?.regionalName,
          value: item?.id,
        };
      });
      setReginList([regins]);

      if (type === 'edit') {
        const reginData = regins?.find((item) => item?.value === cur.areaId);
        if (reginData) {
          handleValue(reginData, cur);
          setReview(cur?.review);
        }
      } else if (type === 'add') {
        const reginData = regins?.find((item) => item?.value === cur.areaId);
        if (reginData) {
          handleValue(reginData, cur);
        }
      }
    }
  };

  const handleValue = async (item, current) => {
    setVisible(false);
    setValue(item);
    vsf.refs.formRecord.resetFields();
    setLabelList([]);
    setPlaceNmae(undefined);
    setGroupName(undefined);
    setLabelId([]);
    vsf.refs.formRecord.setFieldValue('area', item?.label);
    const res =
      await vsf.services.RegionalManagementController_getListPlaceInfoIdVoByRegionalId_24cfcb(
        {
          regionalId: item?.value,
        },
      );
    if (res.code === 200 || res.data) {
      setPlaceList(item?.label);
      if (res?.data?.length > 0) {
        if (type === 'edit') {
          if (!isEdit) {
            const placeData = res.data?.find(
              (cur) => cur.id === current?.placeId,
            );
            if (placeData) {
              vsf.refs.formRecord.setFieldValue('place', [placeData]);
              handlePlaceValue([placeData]);
              setFileList(JSON.parse(current?.reviewImg ?? '[]'));
              setLabelId(
                current?.inspectionRecordLabelDtoList?.map(
                  (item) => item?.labelId,
                ),
              );
            } else {
              vsf?.showToast('当前区域下未找到该场所');
            }
          }
        } else if (type === 'add') {
          const placeData = res.data?.find(
            (cur) => cur.id === current?.placeId,
          );
          if (placeData) {
            vsf.refs.formRecord.setFieldValue('place', [placeData]);
            handlePlaceValue([placeData]);
          } else {
            vsf?.showToast('当前区域下未找到该场所');
          }
        }
      } else {
        vsf?.showToast('当前区域下没有场所');
      }
    }
  };

  const handlePlaceValue = async (item) => {
    setPlaceNmae(item?.[0]?.placeName);
    setPlaceId(item?.[0]?.id);
    const res =
      await vsf.services.InspectionTeamController_queryPlaceTeam_ee6993({
        placeId: item?.[0]?.id,
      });
    if (res.code === 200 || res.data) {
      if (res?.data?.length > 0) {
        vsf.refs.formRecord.setFieldValue('isGroup', res?.data?.[0]?.teamName);
        setGroupName(res?.data?.[0]?.teamName);
        setTeamId(res?.data?.[0]?.id);
        teamId ? handleGetLabelValue() : ' ';
      } else {
        vsf?.showToast('当前场所下未分配分组');
        vsf.refs.formRecord.resetFields();
        handleValue(value);
        setLabelList([]);
        setIsEdit(true);
      }
    }
  };

  const handleGetLabelValue = async () => {
    const res =
      await vsf.services.InspectionTeamController_queryTeamLabel_97b580({
        teamId: teamId,
        labelName: labelName,
      });
    if (res.code === 200 || res.data) {
      setLabelList(res?.data);
    }
  };

  const handleSubmit = async () => {
    const formValue = await vsf.refs.formRecord?.getFieldsValue();
    const paramValue = {
      ...formValue,
      id: editItem?.id ?? undefined,
      place: placeName,
      areaId: value?.value,
      placeId: placeId,
      review,
      reviewImg: JSON.stringify(fileList),
      inspectionRecordLabelBtoList: labelList
        ?.filter((item1) => labelId?.some((item2) => item2 === item1.id))
        ?.map((item) => ({
          labelId: item.id,
          labelName: item.labelName,
        })),
      campusId: currentCampus?.campusId,
      submitter: vsf.stores.global?.userInfo?.name,
    };

    if (!paramValue?.area) {
      return vsf?.showToast('请选择区域');
    } else if (!paramValue?.place) {
      return vsf?.showToast('请选择场所');
    } else if (!paramValue?.isGroup) {
      return vsf?.showToast('请选择分组');
    } else if (paramValue?.inspectionRecordLabelBtoList?.length <= 0) {
      return vsf?.showToast('请选择标签');
    }

    if (type === 'edit') {
      try {
        const res =
          await vsf.services.InspectionCenterInfoController_updateInspectionRecord_20f8d9(
            {
              btoParam: paramValue,
            },
          );
        if (res && res?.code === 200) {
          type
            ? vsf?.goBack()
            : areaId
              ? vsf?.navigateTo(`./InspectionRecord?Id=${areaId}`)
              : vsf?.navigateTo('./InspectionRecord');
          vsf.showToast('提交成功');
        }
      } catch {}
    } else {
      try {
        const res =
          await vsf.services.InspectionCenterInfoController_createInspectionRecord_364494(
            {
              btoParam: paramValue,
            },
          );
        if (res && res?.code === 200) {
          type
            ? vsf?.goBack()
            : areaId
              ? vsf?.navigateTo(`./InspectionRecord?Id=${areaId}`)
              : vsf?.navigateTo('./InspectionRecord');
          vsf.showToast('提交成功');
        }
      } catch {}
    }
  };

  const upLoadImg = async (file) => {
    try {
      const polichResponse = await vsf.services.ApiController_ossUpload_248b32({
        fileName: file?.name,
      });
      if (polichResponse.data) {
        const client = new OSS({
          ...polichResponse.data.data.client,
        });

        const options = {
          callback: {
            ...polichResponse.data.data.callback,
          },
        };

        const res = await client.put(
          polichResponse.data.data.objectKey,
          file,
          options,
        );
        if (res.data) {
          const response = await vsf.services.ApiController_getFileUrl_a34786({
            fileIds: [polichResponse?.data?.data.fileId],
          });
          if (response.data) {
            const url = response.data.data[polichResponse?.data?.data.fileId];
            return Promise.resolve({
              url,
            });
          }
        }
      }
    } catch (error) {
      return Promise.reject(error);
    }
  };

  useEffect(() => {
    if (teamId) {
      handleGetLabelValue();
    }
  }, [labelName, teamId]);

  useEffect(() => {
    if (type) {
      vsf.services
        .InspectionCenterInfoController_getInspectionRecordVoById_ecb410({
          id: recordId,
        })
        .then((response) => {
          if (response.code === 200 || response.data) {
            setEditItem(response?.data);
            reqRegins(response?.data);
          }
        });
    } else {
      reqRegins();
    }
  }, []);
  return (
    <>
      <NavBar
        children={type === 'edit' ? '编辑巡检记录' : '新增巡检记录'}
        backArrow={true}
        onBack={() => {
          type
            ? vsf?.goBack()
            : areaId
              ? vsf?.navigateTo(`./InspectionRecord?Id=${areaId}`)
              : vsf?.navigateTo('./InspectionRecord');
        }}
      />
      <Box className="page-group-create">
        <div className="InspectionRecordFormContainer">
          <Form
            mode="card"
            vsid="37696"
            id="formRecord"
            layout="horizontal"
            requiredMarkStyle="none"
            hasFeedback={true}
            readOnly={readOnly || props.routes.query.type}
            style={{
              '--border-bottom': '1px solid #EBEFF4',
              '--prefix-width': '80px',
              '--text-align': 'right',
            }}
            footer={false}
          >
            <div className="form-box">
              <FormItem
                name="area"
                label="选择区域"
                required={true}
                rules={[{ required: true, message: '请选择区域' }]}
                onClick={() => {
                  reginList?.[0]?.length > 0
                    ? type
                      ? ''
                      : setVisible(true)
                    : vsf?.showToast('当前没有区域数据');
                }}
                className="area-form"
              >
                <Input readOnly placeholder="请选择" />
              </FormItem>

              <FormItem
                name="place"
                label="选择场所"
                required={true}
                rules={[{ required: true, message: '请选择场所' }]}
                className="area-form"
                onClick={() => {
                  if (value) {
                    type ? '' : setPlaceVisible(true);
                  } else {
                    vsf?.showToast('请先选择区域');
                  }
                }}
              >
                <Input readOnly placeholder="请选择" value={placeName} />

                <CheckList
                  isMul={true}
                  visible={placeVisible}
                  regionalId={value?.value}
                  onChange={(v) => {
                    if (v?.length > 0) {
                      handlePlaceValue(v);
                    } else {
                      vsf?.showToast('请选择场所');
                      vsf.refs.formRecord.resetFields();
                      handleValue(value);
                      setLabelList([]);
                      setIsEdit(true);
                    }
                  }}
                  onClose={() => setPlaceVisible(false)}
                />
              </FormItem>

              <FormItem
                name="isGroup"
                label="所在分组"
                required={true}
                rules={[{ required: true, message: '请选择所在分组' }]}
                className="area-form"
              >
                <Input readOnly placeholder="" />
              </FormItem>
            </div>
            <Form.Header></Form.Header>

            <div className="form-box">
              <div className="InspectionRecordFormContainer-label">
                {labelList?.length > 0 || groupName ? (
                  <>
                    <div className="InspectionRecordFormContainer-label__top">
                      <SearchBar
                        clearable
                        onSearch={(v) => {
                          setLabelName(v);
                        }}
                        onChange={(v) => {
                          setLabelName(v);
                        }}
                        onClear={(v) => {
                          setLabelName(undefined);
                        }}
                        placeholder="搜索标签"
                        style={{
                          '--background': '#fff',
                          '--border-radius': '17px',
                          border: '1px solid #ebeff4',
                          '--padding-left': '38%',
                        }}
                        className="custom-search-bar"
                      />
                    </div>

                    {labelList?.length === 0 && (
                      <>
                        <div
                          className="emptyLabel"
                          style={{
                            marginTop: '20px',
                          }}
                        >
                          暂无标签
                        </div>
                      </>
                    )}
                  </>
                ) : (
                  <>
                    <div className="emptyLabel">暂无标签</div>
                  </>
                )}

                <div className="InspectionRecordFormContainer-label__con">
                  {(collapse ? labelList : labelList?.slice(0, 5))?.map(
                    (item, index) => {
                      return (
                        <>
                          <div
                            onClick={() => {
                              handleSetLabelValue(item?.id);
                            }}
                            className={
                              labelId?.indexOf(item?.id) !== -1
                                ? 'InspectionRecordFormContainer-label__con__itemChoose'
                                : 'InspectionRecordFormContainer-label__con__item'
                            }
                            key={index}
                          >
                            {item?.labelName ?? ''}
                            {labelId?.indexOf(item?.id) !== -1 && (
                              <>
                                <div className="InspectionRecordFormContainer-label__con__itemIcon">
                                  <img src={labelCheck} alt="" />
                                </div>
                              </>
                            )}
                          </div>
                        </>
                      );
                    },
                  )}

                  {labelList?.length > 5 && (
                    <>
                      <div
                        className={`collapse`}
                        onClick={() => {
                          setCollapse(!collapse);
                        }}
                      >
                        {collapse ? '收起' : '展开'}
                        <img
                          className={`${collapse ? 'up' : 'down'}`}
                          src={collapseDown}
                          alt=""
                        />
                      </div>
                    </>
                  )}
                </div>
              </div>
            </div>

            <Form.Header></Form.Header>

            <div className="InspectionRecordFormContainer-comment">
              <div className="InspectionRecordFormContainer-comment__top">
                点评 <span>（非必填）</span>
              </div>
              <div>
                <TextArea
                  placeholder="请输入文字～"
                  className="InspectionRecordFormContainer-comment__textarea"
                  value={review}
                  onChange={(val) => {
                    setReview(val);
                  }}
                />
              </div>

              <div className="InspectionRecordFormContainer-comment__image">
                <ImageUploader
                  value={fileList}
                  onChange={(v) => {
                    setFileList(v);
                  }}
                  upload={upLoadImg}
                >
                  <div className="imgBox">
                    <div className="imgCon">
                      <img src={addImg} alt="" />
                    </div>
                    <div className="imgName">上传图片</div>
                  </div>
                </ImageUploader>
              </div>
            </div>
            <Form.Header></Form.Header>
            <div className="InspectionRecordFormContainer-info">
              <div className="InspectionRecordFormContainer-info__item">
                <div className="InspectionRecordFormContainer-info__item__left">
                  提交人
                </div>
                <div className="InspectionRecordFormContainer-info__item__left">
                  {vsf.stores.global?.userInfo?.name}
                </div>
              </div>
              <div className="InspectionRecordFormContainer-info__item">
                <div className="InspectionRecordFormContainer-info__item__left">
                  提交时间
                </div>
                <div className="InspectionRecordFormContainer-info__item__left">
                  {formattedDate}
                </div>
              </div>
            </div>
          </Form>
        </div>
        {visible && (
          <>
            <Picker
              columns={reginList}
              visible={visible}
              title={'选择区域'}
              value={[value?.value]}
              className="my-popup"
              onClose={() => {
                setVisible(false);
              }}
              onConfirm={(id, item) => {
                handleValue(item?.items[0]);
              }}
            />
          </>
        )}

        <div className="InspectionRecordFormContainer-foot">
          <div
            className="InspectionRecordFormContainer-foot__cancel"
            onClick={() => {
              type
                ? vsf?.goBack()
                : areaId
                  ? vsf?.navigateTo(`./InspectionRecord?Id=${areaId}`)
                  : vsf?.navigateTo('./InspectionRecord');
            }}
          >
            取消
          </div>
          <div
            className="InspectionRecordFormContainer-foot__submit"
            onClick={() => {
              handleSubmit();
            }}
          >
            提交
          </div>
        </div>
      </Box>
    </>
  );
};
export default definePage(InspectionRecordForm);

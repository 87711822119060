import './index.less';

import vsf, { definePage } from '@vs/vsf-boot';
import { Box, InfiniteScroll, NavBar } from '@vs/vsf-mobile';
import React, { useEffect, useState } from 'react';

import addSvg from '@/assets/icons/add.svg';
import FixedBtn from '@/modal/fixedBtn';
import MyEmpty from '@/modal/myEmpty';
interface LabelItem {
  id: number;
  createdAt: string;
  regionalName: string;
  campusId: number;
}
const Region: React.FC = (props: any) => {
  const { global } = props?.stores || {};
  const { userInfo, currentCampus } = global || {};
  const [regionList, setRegionList] = useState<LabelItem[]>([]);
  const [hasMore, setHasMore] = useState(true);
  const [from, setFrom] = useState(0);
  const [count, setCount] = useState(-1);

  const addGroup = () => {
    vsf.navigateTo('/add_region');
  };
  const reqRegins = async () => {
    const res =
      (await vsf.services.RegionalManagementController_getPagedByRegionalPageQto_b8dd16(
        {
          qto: {
            campusIdIs: currentCampus?.campusId,
            size: 10,
            from,
          },
        },
      )) as any;
    if (res.code === 200 || res.data) {
      setRegionList([...regionList, ...res.data.result]);
      setCount(res.data.count);
      setHasMore(res.data.result.length === 10);
      setFrom(from + 1);
    } else {
      setHasMore(false);
    }
  };
  const toDetail = (id) => () => {
    vsf.navigateTo(`/add_region?id=${id}`);
  };

  return (
    <>
      <NavBar
        children="区域管理"
        backArrow={true}
        onBack={() => vsf.goBack()}
      />
      <Box className="page-region">
        <div className="list">
          {regionList.map((item) => {
            return (
              <div key={item.id} className="list-group">
                <div className="name">{item.regionalName}</div>
                <div className="btn" onClick={toDetail(item.id)}>
                  编辑
                </div>
              </div>
            );
          })}
        </div>
        {count === 0 ? (
          <MyEmpty
            style={{ height: 'calc(100vh - 340px)' }}
            text="当前还未设置巡检区域"
          />
        ) : (
          <InfiniteScroll loadMore={reqRegins} hasMore={hasMore} />
        )}
        <FixedBtn
          onClick={addGroup}
          icon={<img src={addSvg}></img>}
          text="新增区域"
        />
      </Box>
    </>
  );
};

export default definePage(Region);

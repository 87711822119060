import './index.less';

import vsf from '@vs/vsf-boot';
import { Button, Mask } from '@vs/vsf-mobile';
import React, { useState } from 'react';

interface ConfirmMaskProps {
  text?: string;
  onMaskClick?: () => void;
  onConfirm?: () => void;
  visible: boolean;
}

const ConfirmMask: React.FC<ConfirmMaskProps> = ({
  onConfirm,
  onMaskClick,
  visible,
  text,
}) => {
  const [loading, setLoading] = useState(false);
  const onOk = async () => {
    setLoading(true);
    await onOk();
    setLoading(false);
  };
  return (
    <Mask
      className="confirm-mask-box"
      visible={visible}
      onMaskClick={onMaskClick}
    >
      <div className="mask-content">
        <div className="title">{text}</div>
        <div className="footer">
          <Button className="btn col" onClick={onMaskClick}>
            取消
          </Button>
          <Button loading={loading} className="btn ok" onClick={onConfirm}>
            确定
          </Button>
        </div>
      </div>
    </Mask>
  );
};
export default ConfirmMask;
